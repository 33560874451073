import React, { useEffect } from 'react';
import { useAppDispatch } from '../../store/store';
import { Api } from '../../store/api/generated/Api';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../store/slices/AuthSlice';
import { setUserCheck, userCheckSelector } from '../../store/slices/RuntimeSlice';

export const AuthCheck: React.FC = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userCheck = useSelector(userCheckSelector);

  useEffect(() => {
    const getUserOnce = async () => {
      try {
        // Artificial delay
        // await new Promise((resolve) => setTimeout(resolve, 800));
        if (isAuthenticated && !userCheck) {
          await dispatch(
            Api.endpoints.userControllerGetUserProfile.initiate(undefined, { forceRefetch: true })
          ).unwrap();
        }

        if (!userCheck) {
          dispatch(setUserCheck(true));
        }
      } catch (e) {
        console.error(e);
      }
    };

    getUserOnce();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};
