import { useEffect, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import { Config } from 'tailwindcss';
import theme from '../tailwind.theme.js';

export const TailwindThemeConfig = resolveConfig({ theme } as Config);

export const Screens = ['sm', 'md', 'lg', 'xl', '2xl'] as const;

export type Screen = (typeof Screens)[number];
export function useTailwindBreakpoint() {
  const [breakpoint, setBreakpoint] = useState<Screen>(Screens[0]);

  useEffect(() => {
    const updateBreakpoint = () => {
      const width = window.innerWidth;
      let newBreakpoint: Screen = 'sm';
      Object.entries(TailwindThemeConfig?.theme?.screens as { [key: string]: string }).forEach(
        ([key, value]) => {
          const breakpointWidth = Number(value.replace('px', ''));
          if (breakpointWidth <= width) {
            newBreakpoint = key as Screen;
          }
        }
      );

      if (newBreakpoint !== breakpoint) {
        setBreakpoint(newBreakpoint);
      }
    };
    window.addEventListener('resize', updateBreakpoint);
    updateBreakpoint();

    return () => window.removeEventListener('resize', updateBreakpoint);
  }, [breakpoint]);

  const currentBreakpoint = Screens.indexOf(breakpoint);
  const mobileBreakpointMax = Screens.indexOf('lg');
  const isMobile = mobileBreakpointMax > currentBreakpoint;

  return {
    breakpoint,
    isMobile,
  };
}
