import { Table, TableHeadCellProps as Core } from 'flowbite-react';
import React from 'react';
import { customTheme } from './Table';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrow-down.svg';
import classNames from 'classnames';

export type SortDirection = 'asc' | 'desc' | undefined;
export interface TableHeadCellProps {
  sortable?: boolean;
  sortDirection?: SortDirection;
  setSortDirection?: (direction: SortDirection) => void;
}
export const TableHeadCell: React.FC<TableHeadCellProps & Core> = ({
  children,
  sortable,
  sortDirection,
  setSortDirection,
  ...props
}) => {
  let nextSort: SortDirection = undefined;
  switch (sortDirection) {
    case 'asc':
      nextSort = 'desc';
      break;
    case 'desc':
      nextSort = undefined;
      break;
    default:
      nextSort = 'asc';
      break;
  }
  return (
    <Table.HeadCell {...props} theme={customTheme?.head?.cell}>
      {sortable ? (
        <div
          className="flex cursor-pointer select-none items-center"
          onClick={() => setSortDirection && setSortDirection(nextSort)}
        >
          {children}
          {sortDirection && (
            <ArrowDown
              className={classNames([
                'ml-3 h-2.5 w-2.5 cursor-pointer fill-zinc-500 stroke-zinc-600',
                sortDirection === 'asc' && 'rotate-180',
              ])}
            />
          )}
        </div>
      ) : (
        children
      )}
    </Table.HeadCell>
  );
};
