import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../store/slices/AuthSlice';

export const GuestGuard: React.FC<{ children: React.ReactNode }> = (props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (!isAuthenticated) {
    return props.children as ReactElement;
  }

  return <Navigate to="/properties" replace />;
};
