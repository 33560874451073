import { createSelector, createSlice, Draft } from '@reduxjs/toolkit';
import { AUTH_STATE_NAME, AuthState, User } from '../models/AuthState';
import {
  Api,
  AuthControllerLoginUserApiResponse,
  UserControllerGetUserProfileApiResponse,
} from '../api/generated/Api';

const updateUser = (
  state: Draft<AuthState>,
  {
    payload,
  }: {
    payload: UserControllerGetUserProfileApiResponse;
  }
) => {
  try {
    if (state?.token && !(payload as any)?.error) {
      const { id, email, firstName, lastName } = payload;
      state.user = {
        id,
        email,
        firstName,
        lastName,
      };
    }
  } catch (e) {
    console.error(e);
  }
};

export const AuthSlice = createSlice({
  name: AUTH_STATE_NAME,
  initialState: {
    user: null,
    token: null,
    isAuthenticated: false,
  } as AuthState,
  reducers: {
    setToken: (state, { payload }: { payload: string | null }) => {
      if (payload) {
        state.token = payload;
        state.isAuthenticated = true;
      } else {
        resetAuth();
      }
    },
    setRefreshToken: (state, { payload }: { payload: string | null }) => {
      state.refreshToken = payload;
    },
    handleAuthSuccess: (
      state: Draft<AuthState>,
      { payload }: { payload: AuthControllerLoginUserApiResponse }
    ) => {
      try {
        state.token = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        state.isAuthenticated = !!payload.accessToken;
      } catch (e) {
        console.error(e);
      }
    },
    resetAuth: (state: Draft<AuthState>) => {
      try {
        state.user = null;
        state.token = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(Api.endpoints.userControllerGetUserProfile.matchFulfilled, updateUser);
  },
});

type PartialState = {
  [AuthSlice.name]: AuthState;
  [key: string]: any;
};

export const userSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].user,
  (it) => it
);
export const tokenSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].token,
  (it) => it
);
export const refreshTokenSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].refreshToken,
  (it) => it
);
export const isAuthenticatedSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].isAuthenticated,
  (it) => it
);

export const { resetAuth, handleAuthSuccess, setToken, setRefreshToken } = AuthSlice.actions;
