import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../components/table/Table';
import { TableRow } from '../../components/table/TableRow';
import { TableHead } from '../../components/table/TableHead';
import { TableHeadCell } from '../../components/table/TableHeadCell';
import { TableBody } from '../../components/table/TableBody';
import { TableCell } from '../../components/table/TableCell';
import { SidebarMenu } from '../../components/Sidebar';
import {
  StatsDto,
  usePropertyControllerGetPropertiesWithStatisticsQuery,
} from '../../store/api/generated/Api';
import { MobileHeader } from '../../components/MobileHeader';
import { CustomFlowbiteTheme } from 'flowbite-react';
import { ReactComponent as CircleIcon } from '../../assets/svg/circle.svg';
import { PieChart } from '../../components/Statistics/PieChart';
import { Accordion } from '../../components/accordion/Accordion';
import { AccordionPanel } from '../../components/accordion/AccordionPanel';
import { AccordionTitle } from '../../components/accordion/AccordionTitle';
import { AccordionContent } from '../../components/accordion/AccordionContent';
import { ChartConfiguration } from 'c3';

export const StatisticsPage: React.FC = () => {
  const { t } = useTranslation();

  const { data: statistics } = usePropertyControllerGetPropertiesWithStatisticsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const buttonCustomTheme: CustomFlowbiteTheme['button'] = {
    base: 'lg:hidden h-10 w-full !bg-green-500 !rounded-full focus:outline-none mt-4',
    outline: {
      on: 'flex !p-3.5 justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white',
    },
    inner: {
      base: 'justify-center font-semibold',
    },
  };

  const pattern = ['var(--green-500)', '#E3A008', 'var(--zinc-600)', 'var(--zinc-100)'];

  const pieConfig = (stats: StatsDto): Partial<ChartConfiguration> => ({
    size: {
      width: 150,
      height: 150,
    },
    color: {
      pattern,
    },
    data: {
      columns: [
        ['Inspected', stats.verified],
        ['Skipped', stats.skipped],
        ['In progress', stats.in_progress],
        ['Not inspected', stats.unverified],
      ],
    },
    // svg: { classname: 'p-5' },
    // padding: {
    //   left: 10,
    //   top: 30,
    //   bottom: 30,
    //   right: 10,
    // },
    pie: {
      label: {
        threshold: 0.1,
        ratio: 2.2,
      },
    },
    // onrendered: function (...args) {
    //   if (this.main) {
    //     this.main.selectAll('.c3-chart-arc').selectAll('text');
    //     // @ts-ignore
    //     // .style('fill', ({ index }: { index: number; value: number }) => {
    //     //   return pattern[index] || 'gray';
    //     // });
    //   }
    // },
  });

  return (
    <div className="flex h-full w-full bg-white">
      <SidebarMenu />
      <div className="flex flex-1">
        <div className="relative mx-4 flex h-fit flex-1 flex-col pb-4 lg:px-6">
          <MobileHeader />
          <div className="flex w-full flex-col justify-between gap-4 pt-2 lg:flex-row lg:items-center lg:gap-0 lg:pt-12 xl:pt-8">
            <div className="top-12 text-101 font-semibold text-zinc-950">{t('Statistics')}</div>
          </div>
          <div className="space mt-8 flex flex-row justify-between">
            <div>
              <div className="text-102 left-101 top-20 font-semibold text-zinc-950">
                {t('Property statistics')}
              </div>
            </div>
            <div className="flex flex-row">
              <div className="ml-4 flex flex-row">
                <CircleIcon className="mr-2 mt-2 h-2 w-2 fill-green-500 stroke-green-500" />
                {t('Inspected')}
              </div>
              <div className="ml-4 flex flex-row">
                <CircleIcon className="mr-2 mt-2 h-2 w-2 fill-yellow-400 stroke-yellow-400" />
                {t('Skipped')}
              </div>
              <div className="ml-4 flex flex-row">
                <CircleIcon className="mr-2 mt-2 h-2 w-2 fill-zinc-600 stroke-zinc-600" />
                {t('In progress')}
              </div>
              <div className="ml-4 flex flex-row">
                <CircleIcon className="mr-2 mt-2 h-2 w-2 fill-zinc-200 stroke-zinc-100" />
                {t('Not inspected')}
              </div>
            </div>
          </div>

          <div className="my-5 hidden rounded-xl border border-zinc-50 bg-white pb-3 shadow-md sm:rounded-lg lg:block ">
            <Table className="border-b border-zinc-200 ">
              <TableHead>
                <TableHeadCell scope="col">{t('Property')}</TableHeadCell>
                <TableHeadCell scope="col">{t('No of units')}</TableHeadCell>
                <TableHeadCell scope="col">{t('Inspectors')}</TableHeadCell>
                <TableHeadCell scope="col">{t('Status')}</TableHeadCell>
              </TableHead>
              <TableBody>
                {statistics?.map(({ name, city, address, id, inspectors, stats, units }) => (
                  <TableRow className="cursor-pointer select-none" key={id}>
                    <TableCell>
                      <p className="!text-lg !font-medium">{name}</p>
                      <p>{address}</p>
                    </TableCell>
                    <TableCell>{units}</TableCell>
                    <TableCell className="whitespace-pre-line">{inspectors.join(',\n')}</TableCell>
                    <TableCell>
                      <PieChart {...pieConfig(stats)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="my-5 w-full lg:hidden">
            <Accordion className="flex flex-col gap-3.5 border-none" collapseAll={true}>
              {statistics?.map(({ id, name, address, units, inspectors, stats }) => (
                <AccordionPanel key={id}>
                  <AccordionTitle className="!text-lg !font-medium">
                    <p className="!text-lg !font-medium">{name}</p>
                    <p>{address}</p>
                  </AccordionTitle>
                  <AccordionContent className="-mt-3.5 rounded-b-lg">
                    <div className="mb-2 flex flex-row justify-between">
                      <p className="text-base font-medium not-italic text-zinc-800">
                        {t('No of units')}
                      </p>
                      <p className="text-base font-medium not-italic text-zinc-600">{units}</p>
                    </div>
                    <div className="mb-3 flex flex-row justify-between text-zinc-800">
                      <p className="text-base font-medium not-italic text-zinc-800">
                        {t('Inspectors')}
                      </p>
                      <p className="whitespace-pre-line text-base font-normal not-italic text-zinc-600">
                        {inspectors.join(',\n')}
                      </p>
                    </div>
                    <div className="mb-2 flex flex-row justify-between text-zinc-800">
                      <p className="text-base font-medium not-italic text-zinc-800">
                        {t('Status')}
                      </p>
                      <PieChart {...pieConfig(stats)} />
                    </div>
                  </AccordionContent>
                </AccordionPanel>
              )) || <></>}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};
