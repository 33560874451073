export class Mutex {
  protected promise: null | Promise<void> = null;
  public waitForUnlock(): Promise<void> {
    return this.promise || Promise.resolve();
  }
  isLocked(): boolean {
    return !!this.promise;
  }
  acquire(): Promise<{
    release: () => void;
    block: () => void;
  }> {
    return new Promise((resolveAcquire) => {
      this.promise = new Promise((resolve, reject) => {
        resolveAcquire({
          release: () => {
            resolve();
            this.promise = null;
          },
          block: () => {
            reject();
            this.promise = null;
          },
        });
      });
    });
  }
}
