import { createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { handleAuthSuccess, resetAuth } from '../slices/AuthSlice';
import { Api, AuthControllerLoginUserApiArg, UserDto } from '../api/generated/Api';

export const login = createAsyncThunk<UserDto, AuthControllerLoginUserApiArg>(
  'auth/login',
  async (loginInfo, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await batch(async () => {
      try {
        await dispatch(resetAuth());
        const response = await dispatch(
          Api.endpoints.authControllerLoginUser.initiate(loginInfo)
        ).unwrap();
        await dispatch(handleAuthSuccess(response));
      } catch (error) {
        return rejectWithValue(error);
      }

      return fulfillWithValue(
        await dispatch(
          Api.endpoints.userControllerGetUserProfile.initiate(undefined, { forceRefetch: true })
        ).unwrap()
      );
    });
  }
);
export const logout = createAsyncThunk<void, void>(
  'auth/logout',
  async (_, { dispatch, rejectWithValue }) => {
    return await batch(async () => {
      try {
        await dispatch(Api.endpoints.authControllerLogout.initiate());
      } catch (error) {
        await dispatch(reset());
        return rejectWithValue(error);
      }
      await dispatch(reset());
    });
  }
);
export const refreshToken = createAsyncThunk<void, void>(
  'auth/refreshToken',
  async (_, { dispatch, rejectWithValue }) => {
    return await batch(async () => {
      try {
        const response = await dispatch(
          Api.endpoints.authControllerRefreshTokens.initiate()
        ).unwrap();
        await dispatch(handleAuthSuccess(response));
      } catch (error) {
        await dispatch(reset());
        return rejectWithValue(error);
      }
    });
  }
);

export const reset = createAsyncThunk<void, void>(
  'auth/reset',
  async (_, { dispatch, rejectWithValue }) => {
    return await batch(async () => {
      try {
        await dispatch(resetAuth());
        await dispatch(Api.util.resetApiState());
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }
);
