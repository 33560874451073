import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AUTH_STATE_NAME, AuthState } from '../models/AuthState';
import { Api } from './generated/Api';

export const refreshEndpoint = 'authControllerRefreshTokens';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    headers.set('Accept', `application/json, text/plain, */*`);

    if (!headers.get('Authorization')) {
      // Check if refresh endpoint and if that endpoint exists
      const isRefreshEndpoint =
        endpoint === refreshEndpoint && !!Api.endpoints[refreshEndpoint].initiate(undefined);
      const tokenKey = isRefreshEndpoint ? 'refreshToken' : 'token';
      const token = ((getState() as any)[AUTH_STATE_NAME] as AuthState)[tokenKey];

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }

    return headers;
  },
});
