import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  InspectionAssetDto,
  UnitDto,
  useInspectionAssetControllerGetDamageTypesQuery,
} from '../../store/api/generated/Api';

interface ClaimTotalCostsProps {
  className?: string;
  units: UnitDto[];
}

export const ClaimTotalCosts: React.FC<ClaimTotalCostsProps> = ({ className, units }) => {
  const { t } = useTranslation();
  const { data: types } = useInspectionAssetControllerGetDamageTypesQuery();

  const damages = units.reduce(
    (accUnit, unit) => [
      ...accUnit,
      ...unit.areas.reduce(
        (accArea, area) => [...accArea, ...area.inspectionAssets],
        [] as InspectionAssetDto[]
      ),
    ],
    [] as InspectionAssetDto[]
  );

  const costsGroupedPerType = damages.reduce((accDamage, { damageType, cost }) => {
    damageType = damageType || 'other';
    cost = cost || 0;
    const existingValue = accDamage[damageType] || 0;
    accDamage[damageType] = existingValue + cost;
    return accDamage;
  }, {} as { [key: string]: number });

  const damagesByType = Object.entries(costsGroupedPerType).map(([key, price]) => ({
    ...(types?.find((x) => x.value === key) || { value: key, label: key }),
    price,
  }));

  const totalRenters = units.reduce(
    (totalRentersUnit, unit) =>
      totalRentersUnit +
      unit.areas.reduce(
        (totalRentersArea, area) => totalRentersArea + (area.renters?.length || 0),
        0 as number
      ),
    0
  );
  const totalCost = damages.reduce((acc: number, damage) => acc + (damage.cost || 0), 0);

  return (
    <div className={classNames(['flex flex-col gap-12 print:flex-row lg:flex-row', className])}>
      <div className="flex w-full flex-col justify-center gap-7 rounded-2xl bg-zinc-50 p-8 print:w-[20.625rem] print:py-16 lg:w-[20.625rem] lg:p-16">
        <div className="flex flex-col gap-1 border-l border-green-500 pl-6">
          <div className="text-5xl font-semibold leading-[60px] text-green-500">${totalCost}</div>
          <div className="text-lg font-semibold leading-7 text-zinc-700">{t('Total cost')}</div>
        </div>
        <div className="flex gap-6 print:min-w-[12.5rem]">
          <div className="flex w-1/2 flex-col">
            <div className="text-2xl font-semibold leading-loose text-green-500">
              {damages.length}
            </div>
            <div className="text-base font-medium leading-7 text-zinc-700">
              {t('Number of damages')}
            </div>
          </div>
          <div className="flex w-1/2 flex-col">
            <div className="text-2xl font-semibold leading-loose text-green-500">
              {totalRenters}
            </div>
            <div className="text-base font-medium leading-5 text-zinc-700">
              {t('Covered Renters')}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-7">
        <div className="flex gap-2.5 self-stretch">
          <div className="text-xl font-semibold text-zinc-800">{t('Damages by type')}</div>
        </div>
        <div className="grid w-full max-w-full grid-cols-4 gap-x-6 gap-y-6 print:w-[28.75rem] lg:w-[28.75rem]">
          {damagesByType.map(({ label, value, price }) => (
            <div key={value} className="flex flex-col gap-1">
              <div className="text-sm font-medium leading-tight text-zinc-600">{label}</div>
              <div className="text-xl font-medium leading-loose text-zinc-700">${price}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
