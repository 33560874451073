import { Table, TableHeadProps as Core } from 'flowbite-react';
import React from 'react';
import { customTheme } from './Table';
interface TableHeadProps {}
export const TableHead: React.FC<TableHeadProps & Core> = (props) => {
  return (
    <Table.Head
      {...props}
      theme={customTheme?.head}
      className="group/head !dark:text-zinc-400 !text-[12px] !normal-case leading-none !text-zinc-600"
    />
  );
};
