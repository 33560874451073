import { BaseApi as api } from '../BaseApi';
import { objectToFormData } from '../../../util/objectToFormData';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appControllerGetHello: build.query<
      AppControllerGetHelloApiResponse,
      AppControllerGetHelloApiArg
    >({
      query: () => ({ url: `/` }),
    }),
    authControllerLoginUser: build.mutation<
      AuthControllerLoginUserApiResponse,
      AuthControllerLoginUserApiArg
    >({
      query: (queryArg) => ({ url: `/auth/login`, method: 'POST', body: queryArg.loginDto }),
    }),
    authControllerRegisterUser: build.mutation<
      AuthControllerRegisterUserApiResponse,
      AuthControllerRegisterUserApiArg
    >({
      query: (queryArg) => ({ url: `/auth/register`, method: 'POST', body: queryArg.registerDto }),
    }),
    authControllerLogout: build.mutation<
      AuthControllerLogoutApiResponse,
      AuthControllerLogoutApiArg
    >({
      query: () => ({ url: `/auth/logout`, method: 'POST' }),
    }),
    authControllerRefreshTokens: build.mutation<
      AuthControllerRefreshTokensApiResponse,
      AuthControllerRefreshTokensApiArg
    >({
      query: () => ({ url: `/auth/refresh`, method: 'POST' }),
    }),
    userControllerGetUserProfile: build.query<
      UserControllerGetUserProfileApiResponse,
      UserControllerGetUserProfileApiArg
    >({
      query: () => ({ url: `/user/profile` }),
    }),
    unitControllerGetUnitsForProperty: build.query<
      UnitControllerGetUnitsForPropertyApiResponse,
      UnitControllerGetUnitsForPropertyApiArg
    >({
      query: (queryArg) => ({
        url: `/unit/${queryArg.propertyId}`,
        params: {
          search: queryArg.search,
          pageNumber: queryArg.pageNumber,
          pageSize: queryArg.pageSize,
          filter: queryArg.filter,
          sort: queryArg.sort,
          damages: queryArg.damages,
          status: queryArg.status,
        },
      }),
    }),
    unitControllerGetUnitById: build.query<
      UnitControllerGetUnitByIdApiResponse,
      UnitControllerGetUnitByIdApiArg
    >({
      query: (queryArg) => ({ url: `/unit/${queryArg.propertyId}/${queryArg.unitId}` }),
    }),
    unitControllerGetNextUnitById: build.query<
      UnitControllerGetNextUnitByIdApiResponse,
      UnitControllerGetNextUnitByIdApiArg
    >({
      query: (queryArg) => ({ url: `/unit/next/${queryArg.propertyId}/${queryArg.unitId}` }),
    }),
    areaControllerSkipArea: build.mutation<
      AreaControllerSkipAreaApiResponse,
      AreaControllerSkipAreaApiArg
    >({
      query: (queryArg) => ({ url: `/area/skip/${queryArg.areaId}`, method: 'PATCH' }),
    }),
    areaControllerVerifyArea: build.mutation<
      AreaControllerVerifyAreaApiResponse,
      AreaControllerVerifyAreaApiArg
    >({
      query: (queryArg) => ({ url: `/area/verify/${queryArg.areaId}`, method: 'PATCH' }),
    }),
    areaControllerGetAreaDamages: build.query<
      AreaControllerGetAreaDamagesApiResponse,
      AreaControllerGetAreaDamagesApiArg
    >({
      query: (queryArg) => ({ url: `/area/damages/${queryArg.areaId}` }),
    }),
    propertyControllerGetProperties: build.query<
      PropertyControllerGetPropertiesApiResponse,
      PropertyControllerGetPropertiesApiArg
    >({
      query: (queryArg) => ({ url: `/property`, params: { search: queryArg.search } }),
    }),
    propertyControllerGetPropertiesWithStatistics: build.query<
      PropertyControllerGetPropertiesWithStatisticsApiResponse,
      PropertyControllerGetPropertiesWithStatisticsApiArg
    >({
      query: () => ({ url: `/property/statistics` }),
    }),
    inspectionAssetControllerGetDamageTypes: build.query<
      InspectionAssetControllerGetDamageTypesApiResponse,
      InspectionAssetControllerGetDamageTypesApiArg
    >({
      query: () => ({ url: `/inspection-asset/damage/types` }),
    }),
    inspectionAssetControllerRegisterInspectionAsset: build.mutation<
      InspectionAssetControllerRegisterInspectionAssetApiResponse,
      InspectionAssetControllerRegisterInspectionAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/inspection-asset/${queryArg.areaId}`,
        method: 'POST',
        body: objectToFormData(queryArg.createInspectionAssetDto),
      }),
    }),
    inspectionAssetControllerPatchInspectionAsset: build.mutation<
      InspectionAssetControllerPatchInspectionAssetApiResponse,
      InspectionAssetControllerPatchInspectionAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/inspection-asset/${queryArg.inspectionAssetId}`,
        method: 'PATCH',
        body: objectToFormData(queryArg.updateInspectionAssetDto),
      }),
    }),
    inspectionAssetControllerDeleteInspectionAsset: build.mutation<
      InspectionAssetControllerDeleteInspectionAssetApiResponse,
      InspectionAssetControllerDeleteInspectionAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/inspection-asset/${queryArg.inspectionAssetId}`,
        method: 'DELETE',
      }),
    }),
    inspectionAssetControllerDeleteAllInspectionAssets: build.mutation<
      InspectionAssetControllerDeleteAllInspectionAssetsApiResponse,
      InspectionAssetControllerDeleteAllInspectionAssetsApiArg
    >({
      query: (queryArg) => ({ url: `/inspection-asset/all/${queryArg.areaId}`, method: 'DELETE' }),
    }),
    inspectionAssetControllerDeleteInspectionImage: build.mutation<
      InspectionAssetControllerDeleteInspectionImageApiResponse,
      InspectionAssetControllerDeleteInspectionImageApiArg
    >({
      query: (queryArg) => ({
        url: `/inspection-asset/image/${queryArg.inspectionAssetImageId}`,
        method: 'DELETE',
      }),
    }),
    imageControllerGetImageFromS3: build.query<
      ImageControllerGetImageFromS3ApiResponse,
      ImageControllerGetImageFromS3ApiArg
    >({
      query: (queryArg) => ({
        url: `/image/${queryArg.propertyName}/${queryArg.unitNumber}/${queryArg.imageId}`,
      }),
    }),
    imageControllerGetThumbnailFromS3: build.query<
      ImageControllerGetThumbnailFromS3ApiResponse,
      ImageControllerGetThumbnailFromS3ApiArg
    >({
      query: (queryArg) => ({
        url: `/image/thumbnail/${queryArg.propertyName}/${queryArg.unitNumber}/${queryArg.imageId}`,
      }),
    }),
    claimControllerGetClaimsForProperty: build.query<
      ClaimControllerGetClaimsForPropertyApiResponse,
      ClaimControllerGetClaimsForPropertyApiArg
    >({
      query: (queryArg) => ({
        url: `/claim/${queryArg.propertyId}`,
        params: {
          search: queryArg.search,
          pageNumber: queryArg.pageNumber,
          pageSize: queryArg.pageSize,
          filter: queryArg.filter,
          sort: queryArg.sort,
        },
      }),
    }),
    claimControllerRegisterClaim: build.mutation<
      ClaimControllerRegisterClaimApiResponse,
      ClaimControllerRegisterClaimApiArg
    >({
      query: (queryArg) => ({
        url: `/claim/${queryArg.propertyId}`,
        method: 'POST',
        body: queryArg.registerClaimDto,
      }),
    }),
    claimControllerUpdateClaim: build.mutation<
      ClaimControllerUpdateClaimApiResponse,
      ClaimControllerUpdateClaimApiArg
    >({
      query: (queryArg) => ({
        url: `/claim/${queryArg.propertyId}/${queryArg.claimId}`,
        method: 'PATCH',
        body: queryArg.updateClaimDto,
      }),
    }),
    claimControllerGetClaimById: build.query<
      ClaimControllerGetClaimByIdApiResponse,
      ClaimControllerGetClaimByIdApiArg
    >({
      query: (queryArg) => ({ url: `/claim/retreive/${queryArg.propertyId}/${queryArg.claimId}` }),
    }),
    claimControllerGetClaimPreview: build.query<
      ClaimControllerGetClaimPreviewApiResponse,
      ClaimControllerGetClaimPreviewApiArg
    >({
      query: (queryArg) => ({
        url: `/claim/preview/${queryArg.propertyId}`,
        params: { unitsIds: queryArg.unitsIds, allUnits: queryArg.allUnits },
      }),
    }),
    claimControllerGetClaimReportFromS3: build.query<
      ClaimControllerGetClaimReportFromS3ApiResponse,
      ClaimControllerGetClaimReportFromS3ApiArg
    >({
      query: (queryArg) => ({
        url: `/claim/report/${queryArg.propertyName}/${queryArg.claimReportId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as Api };
export type AppControllerGetHelloApiResponse = unknown;
export type AppControllerGetHelloApiArg = void;
export type AuthControllerLoginUserApiResponse = /** status 200  */ LoginResponseDto;
export type AuthControllerLoginUserApiArg = {
  loginDto: LoginDto;
};
export type AuthControllerRegisterUserApiResponse = /** status 200  */ UserDto;
export type AuthControllerRegisterUserApiArg = {
  registerDto: RegisterDto;
};
export type AuthControllerLogoutApiResponse = /** status 200  */ MessageResponseDto;
export type AuthControllerLogoutApiArg = void;
export type AuthControllerRefreshTokensApiResponse = /** status 200  */ LoginResponseDto;
export type AuthControllerRefreshTokensApiArg = void;
export type UserControllerGetUserProfileApiResponse = /** status 200  */ UserDto;
export type UserControllerGetUserProfileApiArg = void;
export type UnitControllerGetUnitsForPropertyApiResponse = /** status 200  */ PagedResultDto & {
  items?: UnitDto[];
};
export type UnitControllerGetUnitsForPropertyApiArg = {
  propertyId: string;
  search?: string;
  pageNumber?: number;
  pageSize?: number;
  /** Filter by column name. */
  filter?: string;
  sort?: SortingOrderPaginationKey;
  damages?: boolean;
  status?: UnitStatus;
};
export type UnitControllerGetUnitByIdApiResponse = /** status 200  */ UnitAggregateDto;
export type UnitControllerGetUnitByIdApiArg = {
  propertyId: string;
  unitId: string;
};
export type UnitControllerGetNextUnitByIdApiResponse = /** status 200  */ UnitAggregateDto;
export type UnitControllerGetNextUnitByIdApiArg = {
  propertyId: string;
  unitId: string;
};
export type AreaControllerSkipAreaApiResponse = /** status 200  */ AreaDto;
export type AreaControllerSkipAreaApiArg = {
  areaId: string;
};
export type AreaControllerVerifyAreaApiResponse = /** status 200  */ AreaDto;
export type AreaControllerVerifyAreaApiArg = {
  areaId: string;
};
export type AreaControllerGetAreaDamagesApiResponse = /** status 200  */ InspectionAssetDto[];
export type AreaControllerGetAreaDamagesApiArg = {
  areaId: string;
};
export type PropertyControllerGetPropertiesApiResponse = /** status 200  */ PropertyDto[];
export type PropertyControllerGetPropertiesApiArg = {
  /** Search parameter. Optional */
  search?: string;
};
export type PropertyControllerGetPropertiesWithStatisticsApiResponse =
  /** status 200  */ PropertyStatsDto[];
export type PropertyControllerGetPropertiesWithStatisticsApiArg = void;
export type InspectionAssetControllerGetDamageTypesApiResponse =
  /** status 200  */ DamageTypeResponseDto[];
export type InspectionAssetControllerGetDamageTypesApiArg = void;
export type InspectionAssetControllerRegisterInspectionAssetApiResponse =
  /** status 200  */ InspectionAssetDto;
export type InspectionAssetControllerRegisterInspectionAssetApiArg = {
  areaId: string;
  createInspectionAssetDto: CreateInspectionAssetDto;
};
export type InspectionAssetControllerPatchInspectionAssetApiResponse =
  /** status 200  */ InspectionAssetDto;
export type InspectionAssetControllerPatchInspectionAssetApiArg = {
  inspectionAssetId: string;
  updateInspectionAssetDto: UpdateInspectionAssetDto;
};
export type InspectionAssetControllerDeleteInspectionAssetApiResponse =
  /** status 200  */ MessageResponseDto;
export type InspectionAssetControllerDeleteInspectionAssetApiArg = {
  inspectionAssetId: string;
};
export type InspectionAssetControllerDeleteAllInspectionAssetsApiResponse =
  /** status 200  */ MessageResponseDto;
export type InspectionAssetControllerDeleteAllInspectionAssetsApiArg = {
  areaId: string;
};
export type InspectionAssetControllerDeleteInspectionImageApiResponse =
  /** status 200  */ MessageResponseDto;
export type InspectionAssetControllerDeleteInspectionImageApiArg = {
  inspectionAssetImageId: string;
};
export type ImageControllerGetImageFromS3ApiResponse = /** status 200  */ undefined;
export type ImageControllerGetImageFromS3ApiArg = {
  propertyName: string;
  unitNumber: string;
  imageId: string;
};
export type ImageControllerGetThumbnailFromS3ApiResponse = /** status 200  */ undefined;
export type ImageControllerGetThumbnailFromS3ApiArg = {
  propertyName: string;
  unitNumber: string;
  imageId: string;
};
export type ClaimControllerGetClaimsForPropertyApiResponse = /** status 200  */ PagedResultDto & {
  items?: ClaimDto[];
};
export type ClaimControllerGetClaimsForPropertyApiArg = {
  propertyId: string;
  search?: string;
  pageNumber?: number;
  pageSize?: number;
  /** Filter by column name. */
  filter?: string;
  sort?: SortingOrderPaginationKey;
};
export type ClaimControllerRegisterClaimApiResponse = /** status 200  */ ClaimDto;
export type ClaimControllerRegisterClaimApiArg = {
  propertyId: string;
  registerClaimDto: RegisterClaimDto;
};
export type ClaimControllerUpdateClaimApiResponse = /** status 200  */ ClaimDto;
export type ClaimControllerUpdateClaimApiArg = {
  propertyId: string;
  claimId: string;
  updateClaimDto: UpdateClaimDto;
};
export type ClaimControllerGetClaimByIdApiResponse = /** status 200  */ ClaimAggregateDto;
export type ClaimControllerGetClaimByIdApiArg = {
  propertyId: string;
  claimId: string;
};
export type ClaimControllerGetClaimPreviewApiResponse = /** status 200  */ ClaimAggregateDto;
export type ClaimControllerGetClaimPreviewApiArg = {
  propertyId: string;
  unitsIds?: string[];
  allUnits?: boolean;
};
export type ClaimControllerGetClaimReportFromS3ApiResponse = /** status 200  */ undefined;
export type ClaimControllerGetClaimReportFromS3ApiArg = {
  propertyName: string;
  claimReportId: string;
};
export type LoginResponseDto = {
  accessToken: string;
  refreshToken: string;
};
export type BadRequestResponseDto = {
  statusCode: number;
  message: object;
};
export type UnauthorizedResponseDto = {
  statusCode: number;
  message: string;
};
export type InternalServerErrorResponseDto = {
  statusCode: number;
  message: string;
};
export type LoginDto = {
  email: string;
  password: string;
};
export type UserDto = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  refreshToken: string;
};
export type RegisterDto = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};
export type MessageResponseDto = {
  msg: string;
};
export type ForbiddenResponseDto = {
  statusCode: number;
  message: string;
};
export type NotFoundResponseDto = {
  statusCode: number;
  message: string;
};
export type PagedResultDto = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  searchTerm: string;
};
export type RenterDto = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};
export type InspectionAssetImageDto = {
  id: string;
  imageName: string;
  imageUrl: string;
  thumbnailUrl: string;
  inspectionAssetId: string;
};
export type InspectionAssetDto = {
  id: string;
  inspectionId: string;
  areaId: string;
  comments?: string;
  damageLevel?: string;
  damageType?: string;
  cost?: number;
  inspectionImages: InspectionAssetImageDto[];
};
export type AreaDto = {
  id: string;
  name: string;
  number: string;
  type: string;
  occupied?: boolean;
  description?: string;
  moveOutDate?: string;
  renters: RenterDto[];
  damages?: number;
  status: 'unverified' | 'verified' | 'skipped';
  inspectionAssets: InspectionAssetDto[];
};
export type UnitDto = {
  id: string;
  number: string;
  type: string;
  description?: string;
  sequence: number;
  propertyId: string;
  damages: number;
  status: 'unverified' | 'verified' | 'skipped' | 'in_progress';
  renters: RenterDto[];
  areas: AreaDto[];
  lastUpdatedBy?: string;
  lastUpdatedAt?: string;
};
export type SortingOrderPaginationKey = 'asc' | 'desc';
export type UnitStatus = 'unverified' | 'verified' | 'skipped' | 'in_progress';
export type UnitAggregateDto = {
  unit: UnitDto;
  lastUpdatedAt?: string;
  lastUpdatedBy?: string;
};
export type PropertyDto = {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  type?: string;
};
export type StatsDto = {
  verified: number;
  skipped: number;
  unverified: number;
  in_progress: number;
};
export type PropertyStatsDto = {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  type?: string;
  units: number;
  stats: StatsDto;
  inspectors: string[];
};
export type DamageTypeResponseDto = {
  label: string;
  value: string;
};
export type CreateInspectionAssetDto = {
  files?: Blob[];
};
export type UpdateInspectionAssetDto = {
  comments?: string;
  damageLevel?: string;
  damageType?: string;
  files?: Blob[];
};
export type ClaimReportDto = {
  id: string;
  claimReportName: string;
  claimReportUrl: string;
  claimId: string;
};
export type ClaimDto = {
  id: string;
  name: string;
  date: string;
  propertyId: string;
  createdById: string;
  claimReport: ClaimReportDto;
};
export type UpdateInspectionAssetCostDto = {
  id: string;
  cost: number;
};
export type RegisterClaimDto = {
  inspectionAssetsCosts?: UpdateInspectionAssetCostDto[];
  claimReportUrl: string;
  unitsIds?: string[];
  allUnits?: boolean;
};
export type UpdateClaimDto = {
  inspectionAssetsCosts?: UpdateInspectionAssetCostDto[];
  claimReportUrl: string;
};
export type ClaimAggregateDto = {
  claim: ClaimDto;
  property: PropertyDto;
  units: UnitDto[];
};
export const {
  useAppControllerGetHelloQuery,
  useAuthControllerLoginUserMutation,
  useAuthControllerRegisterUserMutation,
  useAuthControllerLogoutMutation,
  useAuthControllerRefreshTokensMutation,
  useUserControllerGetUserProfileQuery,
  useUnitControllerGetUnitsForPropertyQuery,
  useUnitControllerGetUnitByIdQuery,
  useUnitControllerGetNextUnitByIdQuery,
  useAreaControllerSkipAreaMutation,
  useAreaControllerVerifyAreaMutation,
  useAreaControllerGetAreaDamagesQuery,
  usePropertyControllerGetPropertiesQuery,
  usePropertyControllerGetPropertiesWithStatisticsQuery,
  useInspectionAssetControllerGetDamageTypesQuery,
  useInspectionAssetControllerRegisterInspectionAssetMutation,
  useInspectionAssetControllerPatchInspectionAssetMutation,
  useInspectionAssetControllerDeleteInspectionAssetMutation,
  useInspectionAssetControllerDeleteAllInspectionAssetsMutation,
  useInspectionAssetControllerDeleteInspectionImageMutation,
  useImageControllerGetImageFromS3Query,
  useImageControllerGetThumbnailFromS3Query,
  useClaimControllerGetClaimsForPropertyQuery,
  useClaimControllerRegisterClaimMutation,
  useClaimControllerUpdateClaimMutation,
  useClaimControllerGetClaimByIdQuery,
  useClaimControllerGetClaimPreviewQuery,
  useClaimControllerGetClaimReportFromS3Query,
} = injectedRtkApi;
