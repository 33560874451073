import React from 'react';
import { ReactComponent as LogoIcon } from '../assets/svg/logo.svg';
import { ReactComponent as MenuIcon } from '../assets/svg/menu.svg';
import { useAppDispatch } from '../store/store';
import { setMobileMenuOpen } from '../store/slices/RuntimeSlice';

export const MobileHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="sticky left-0 top-0 z-1 -ml-4 mr-[-1.2rem] flex w-[calc(100%+2rem)] items-center justify-between border-b border-zinc-300 bg-white px-4 pb-4 pt-4 lg:hidden">
      <LogoIcon className="w-[5.8rem] fill-green-500 stroke-green-500" />
      <MenuIcon
        onClick={() => dispatch(setMobileMenuOpen(true))}
        className="h-6 w-6 fill-zinc-500 stroke-zinc-500"
      />
    </div>
  );
};
