import { CustomFlowbiteTheme, Table as CoreTable } from 'flowbite-react';
import React from 'react';
import { TableProps as CoreTableProps } from 'flowbite-react/lib/esm/components/Table/Table';
interface TableProps {}

export const customTheme: CustomFlowbiteTheme['table'] = {
  root: {
    base: 'w-full text-left text-sm text-zinc-600 dark:text-zinc-400',
    shadow:
      'absolute bg-white dark:bg-black w-full h-full top-0 left-0 rounded-lg drop-shadow-md -z-10',
    wrapper: 'relative',
  },
  body: {
    base: 'group/body divide-y',
    cell: {
      base: 'whitespace-nowrap font-normal dark:text-white group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg lg:px-2 xl:px-6 lg:py-1 xl:py-4 leading-tight text-zinc-700',
    },
  },
  head: {
    cell: {
      base: 'group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg bg-zinc-50 dark:bg-zinc-700 lg:px-2 xl:px-6 py-[15px] text-xs',
    },
  },
  row: {
    base: 'group/row h-[72px] border-t border-zinc-200',
    hovered: 'hover:bg-zinc-50 dark:hover:bg-zinc-600',
    striped: 'odd:bg-white even:bg-zinc-50 odd:dark:bg-zinc-800 even:dark:bg-zinc-700',
  },
};
export const Table: React.FC<TableProps & CoreTableProps> = (props) => {
  return <CoreTable {...props} theme={customTheme} />;
};
