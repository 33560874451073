import { QueryStatus, setupListeners } from '@reduxjs/toolkit/query';
import { Action, combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { defaultMiddleware } from './middleware';
import storage from 'redux-persist/lib/storage';
import { useDispatch } from 'react-redux';
import { AuthSlice } from './slices/AuthSlice';
import { createReduxHistoryContext, RouterState } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { RuntimeSlice } from './slices/RuntimeSlice';
import { Api } from './api/generated/Api';

const browserHistory = createBrowserHistory();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: browserHistory as any,
  reduxTravelling: true,
  savePreviousLocations: 5,
});

const reducers = combineReducers({
  router: routerReducer,
  [AuthSlice.name]: AuthSlice.reducer,
  [RuntimeSlice.name]: RuntimeSlice.reducer,
  [Api.reducerPath]: Api.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router', RuntimeSlice.name, Api.reducerPath],
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  middleware: (getDefaultMiddleware) => [
    ...defaultMiddleware(getDefaultMiddleware),
    routerMiddleware,
    Api.middleware,
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();

export const currentRouteSelector = (state: RootState) => state.router as RouterState;

export const isApiLoadingSelector = (state: RootState) => {
  return (
    Object.values(state[Api.reducerPath].queries).some((query: any) => {
      return query && (query.status as QueryStatus) === QueryStatus.pending;
    }) ||
    Object.values(state[Api.reducerPath].mutations).some((mutation: any) => {
      return mutation && (mutation.status as QueryStatus) === QueryStatus.pending;
    })
  );
};

export const apiErrorSelector = (state: RootState) => {
  return [
    ...Object.values(state[Api.reducerPath].queries).filter((query: any) => {
      return query && (query.status as QueryStatus) === QueryStatus.rejected;
    }),
    ...Object.values(state[Api.reducerPath].mutations).filter((mutation: any) => {
      return mutation && (mutation.status as QueryStatus) === QueryStatus.rejected;
    }),
  ];
};

export const history = createReduxHistory(store);

setupListeners(store.dispatch);
