import React, { useEffect, useRef, useState } from 'react';
import c3, { ChartAPI, ChartConfiguration } from 'c3';
import mergeDeep from 'lodash-es/merge';

export const PieChart: React.FC<Partial<ChartConfiguration & { className?: string }>> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<ChartAPI | undefined>();
  useEffect(() => {
    if (ref.current) {
      try {
        const chartObject = c3.generate(
          mergeDeep(
            {
              bindto: ref.current,
              data: {
                type: 'pie',
              },
              legend: {
                show: false,
              },
            } as ChartConfiguration,
            props
          )
        );

        setChart(chartObject);
      } catch (e) {
        console.error(e);
      }
    }

    return () => {
      if (chart && chart?.internal?.resizeFunction) {
        try {
          chart.destroy();
        } catch (e) {
          console.error(e);
        }
        setChart(undefined);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, props]);
  return <div ref={ref} className={props.className} />;
};
