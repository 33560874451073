import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../components/table/Table';
import { TableRow } from '../../components/table/TableRow';
import { TableHead } from '../../components/table/TableHead';
import { TableHeadCell } from '../../components/table/TableHeadCell';
import { TableBody } from '../../components/table/TableBody';
import { TableCell } from '../../components/table/TableCell';
import { PaginationWithIcons } from '../../components/Pagination';
import { DropdownItems } from '../../components/Dropdown';
import { SidebarMenu } from '../../components/Sidebar';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import { push, replace } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import {
  Api,
  PropertyDto,
  UnitControllerGetUnitsForPropertyApiResponse,
  UnitDto,
  usePropertyControllerGetPropertiesQuery,
} from '../../store/api/generated/Api';
import moment from 'moment';
import { Accordion } from '../../components/accordion/Accordion';
import { AccordionPanel } from '../../components/accordion/AccordionPanel';
import { AccordionTitle } from '../../components/accordion/AccordionTitle';
import { AccordionContent } from '../../components/accordion/AccordionContent';
import { MobileHeader } from '../../components/MobileHeader';
import { Button, CustomFlowbiteTheme } from 'flowbite-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useServerTable } from '../../util/useServerTable';
import { useParams } from 'react-router-dom';
import { ReactComponent as VerifiedIcon } from '../../assets/svg/verified.svg';
import { ReactComponent as WorkInProgressIcon } from '../../assets/svg/work-in-progress.svg';
import { ReactComponent as SkippingIcon } from '../../assets/svg/skipped.svg';

export const PropertiesPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { propertyId } = useParams() as {
    propertyId: string;
  };

  const { data: properties } = usePropertyControllerGetPropertiesQuery({});
  const property = properties?.find(({ id }) => id === propertyId);

  const {
    currentPage,
    searchValue,
    setSearchValue,
    hasSorting,
    tableData,
    infiniteScrollItems,
    setCurrentPage,
    fetchMoreItems,
  } = useServerTable<UnitDto, UnitControllerGetUnitsForPropertyApiResponse>({
    fetchItems: useMemo(
      () =>
        async ({ sort, currentPage, search }) => {
          try {
            if (property) {
              return await dispatch(
                Api.endpoints.unitControllerGetUnitsForProperty.initiate(
                  {
                    pageSize: 15,
                    propertyId: property.id,
                    pageNumber: currentPage,
                    filter: sort?.column || 'sequence',
                    sort: sort?.direction || 'asc',
                    search,
                  },
                  { forceRefetch: true }
                )
              ).unwrap();
            }
          } catch (e) {}
          return {
            items: [],
            totalPages: 0,
            currentPage: 0,
            searchTerm: '',
            totalItems: 0,
          };
        },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [property]
    ),
    defaultSetValue: [],
  });

  const setProperty = (property: PropertyDto) => {
    dispatch(replace(`/properties/${property.id}`));
    setCurrentPage(1);
  };

  const onItemClick = (propertyId = '', unitId: string) => {
    dispatch(push(`/unit/${encodeURIComponent(propertyId)}/${encodeURIComponent(unitId)}`));
  };

  useEffect(() => {
    if (!property && properties?.length) {
      setProperty(properties[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, property]);

  const buttonCustomTheme: CustomFlowbiteTheme['button'] = {
    base: 'lg:hidden h-10 w-full !bg-green-500 !rounded-full focus:outline-none mt-4',
    outline: {
      on: 'flex !p-3.5 justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white',
    },
    inner: {
      base: 'justify-center font-semibold',
    },
  };

  return (
    <div className="flex h-full w-full bg-white">
      <SidebarMenu />
      <div className="flex flex-1">
        <div className="relative mx-4 flex h-fit flex-1 flex-col pb-4 lg:px-6">
          <MobileHeader />
          <div className="flex w-full flex-col justify-between gap-4 pt-2 lg:flex-row lg:items-center lg:gap-0 lg:pt-12 xl:pt-8">
            <div className="left-101 top-12 text-101 font-semibold text-zinc-950">
              {t('Property')}
            </div>
            <div className="flex flex-1 flex-col-reverse gap-5 lg:ml-5 lg:flex-row lg:justify-end lg:gap-0">
              <div className="relative flex flex-1 gap-1.5 self-stretch rounded-101 lg:mr-5 lg:justify-end">
                <div className="relative flex-1 lg:flex lg:justify-end">
                  <div className="pointer-events-none absolute left-3 top-2.5 flex items-center lg:relative lg:-right-[2.3rem] lg:left-auto lg:top-0">
                    <SearchIcon className="h-6 w-6 fill-zinc-500 stroke-zinc-500" />
                  </div>
                  <input
                    value={searchValue}
                    onInput={(e) => setSearchValue((e.target as HTMLInputElement).value)}
                    placeholder={t('Search rooms, units, etc')}
                    type="search"
                    className="h-11 w-full rounded-101 p-4 pl-10 text-base font-normal leading-normal text-zinc-500 !outline-none !ring-transparent duration-300 ease-in lg:w-72 lg:focus:w-full"
                  />
                </div>
              </div>
              <DropdownItems
                className="flex-1 lg:min-w-[12.2rem]"
                options={properties}
                value={property}
                onInput={setProperty}
                searchable={true}
              />
            </div>
          </div>

          <div className="my-5 hidden rounded-xl border border-zinc-50 bg-white pb-3 shadow-md sm:rounded-lg lg:block ">
            <Table hoverable className="border-b border-zinc-200 ">
              <TableHead>
                <TableHeadCell scope="col" {...hasSorting('number')}>
                  {t('Unit')}
                </TableHeadCell>
                <TableHeadCell scope="col" {...hasSorting('type')}>
                  {t('Type')}
                </TableHeadCell>
                <TableHeadCell scope="col" {...hasSorting('status')}>
                  {t('Status')}
                </TableHeadCell>
                <TableHeadCell scope="col" {...hasSorting('lastUpdatedAt')}>
                  {t('Last update')}
                </TableHeadCell>
                <TableHeadCell scope="col" {...hasSorting('lastUpdatedBy')}>
                  {t('Last inspected by')}
                </TableHeadCell>
                <TableHeadCell scope="col">{t('Renter')}</TableHeadCell>
                <TableHeadCell scope="col" {...hasSorting('damages')}>
                  {t('Damages')}
                </TableHeadCell>
              </TableHead>
              <TableBody>
                {tableData.items?.map(
                  ({
                    number,
                    type,
                    lastUpdatedAt,
                    id,
                    lastUpdatedBy,
                    renters,
                    damages,
                    status,
                  }) => (
                    <TableRow
                      className="cursor-pointer select-none"
                      key={id}
                      onClick={() => onItemClick(property?.id, id)}
                    >
                      <TableCell className="!text-lg !font-medium">{number}</TableCell>
                      <TableCell>{type}</TableCell>
                      <TableCell>
                        {status === 'verified' && (
                          <div className="flex w-fit items-center gap-1 rounded-2xl bg-green-100 py-0.5 pl-2 pr-2.5 mix-blend-multiply">
                            <VerifiedIcon className="h-4 w-4 fill-green-600 stroke-green-600" />
                            <p className="text-center text-sm font-semibold not-italic text-green-600">
                              {t('Inspected')}
                            </p>
                          </div>
                        )}
                        {status === 'skipped' && (
                          <div className="flex w-fit items-center gap-1 rounded-2xl bg-yellow-100 py-0.5 pl-2 pr-2.5 mix-blend-multiply">
                            <SkippingIcon className="h-4 w-4 fill-yellow-400 stroke-yellow-400" />
                            <p className="text-center text-sm font-semibold not-italic text-yellow-400">
                              {t('Skipped')}
                            </p>
                          </div>
                        )}
                        {status === 'in_progress' && (
                          <div className="flex w-fit items-center gap-1 rounded-2xl bg-zinc-100 py-0.5 pl-2 pr-2.5 mix-blend-multiply">
                            <WorkInProgressIcon className="h-4 w-4 fill-zinc-800 stroke-zinc-800" />
                            <p className="text-center text-sm font-semibold not-italic text-zinc-800">
                              {t('In progress')}
                            </p>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        {lastUpdatedAt ? moment(lastUpdatedAt).format('LLL') : ''}
                      </TableCell>
                      <TableCell>{lastUpdatedBy}</TableCell>
                      <TableCell className="whitespace-pre-line">{renters.join(',\n')}</TableCell>
                      <TableCell>{damages}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
            <PaginationWithIcons
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={tableData.totalPages}
            />
          </div>

          <div className="my-5 w-full lg:hidden">
            <InfiniteScroll
              next={fetchMoreItems}
              hasMore={currentPage < infiniteScrollItems.totalPages}
              loader={<></>}
              dataLength={(infiniteScrollItems.items?.length || 0) * 2}
            >
              <Accordion className="flex flex-col gap-3.5 border-none" collapseAll={true}>
                {infiniteScrollItems.items?.map(
                  ({
                    number,
                    type,
                    lastUpdatedAt,
                    id,
                    lastUpdatedBy,
                    renters,
                    damages,
                    status,
                  }) => (
                    <AccordionPanel key={id}>
                      <AccordionTitle className="!text-lg !font-medium">
                        <div className="flex flex-row justify-between">
                          <p
                            onClick={() => onItemClick(property?.id, id)}
                            className="!text-lg !font-medium"
                          >
                            {number} {!!type && `(${type})`}
                          </p>
                          <div className="-mr-4 flex flex-row justify-start">
                            {status === 'verified' && (
                              <>
                                <span className="my-1 mr-1.5 text-sm font-semibold leading-tight text-zinc-900">
                                  ({damages})
                                </span>
                                <div className="flex h-6 w-6 shrink-0 items-center rounded-full bg-green-100">
                                  <VerifiedIcon className="ml-1 h-4 w-4 shrink-0 fill-green-600 stroke-green-600" />
                                </div>
                              </>
                            )}
                            {status === 'skipped' && (
                              <>
                                <span className="my-1 mr-1.5 text-sm font-semibold leading-tight text-zinc-900">
                                  ({damages})
                                </span>
                                <div className="flex h-6 w-6 shrink-0 items-center rounded-full bg-yellow-100">
                                  <SkippingIcon className="ml-1 h-4 w-4 shrink-0 fill-yellow-400 stroke-yellow-400" />
                                </div>
                              </>
                            )}
                            {status === 'in_progress' && (
                              <>
                                <span className="my-1 mr-1.5 text-sm font-semibold leading-tight text-zinc-900">
                                  ({damages})
                                </span>
                                <div className="flex h-6 w-6 shrink-0 items-center rounded-full bg-zinc-100">
                                  <WorkInProgressIcon className="ml-1 h-4 w-4 shrink-0 fill-zinc-800 stroke-zinc-800" />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </AccordionTitle>
                      <AccordionContent className="-mt-3.5 rounded-b-lg">
                        <div className="mb-2 flex flex-row justify-between">
                          <p className="text-base font-medium not-italic text-zinc-800">
                            {t('Last inspected by')}
                          </p>
                          <p className="text-base font-medium not-italic text-zinc-600">
                            {lastUpdatedBy}
                          </p>
                        </div>
                        <div className="mb-3 flex flex-row justify-between text-zinc-800">
                          <p className="text-base font-medium not-italic text-zinc-800">
                            {t('Last update')}
                          </p>
                          <p className="text-base font-normal not-italic text-zinc-600">
                            {lastUpdatedAt ? moment(lastUpdatedAt).format('LL') : ''}
                          </p>
                        </div>
                        <div className="mb-1 flex flex-row justify-between">
                          <p className="text-base font-medium not-italic text-zinc-800">
                            {t('Renter')}
                          </p>
                          <p className="whitespace-pre-line text-base font-normal not-italic text-zinc-600">
                            {renters.join(', \n')}
                          </p>
                        </div>
                        <div className="mb-2 flex flex-row justify-between text-zinc-800">
                          <p className="text-base font-medium not-italic text-zinc-800">
                            {t('Damages')}
                          </p>
                          <p className="text-base font-normal not-italic text-zinc-600">
                            {damages}
                          </p>
                        </div>
                        {/*<div className="flex">*/}
                        <Button
                          onClick={() =>
                            dispatch(
                              push(
                                `/unit/${encodeURIComponent(
                                  property?.id || ''
                                )}/${encodeURIComponent(id)}`
                              )
                            )
                          }
                          theme={buttonCustomTheme}
                        >
                          {t('Edit')}
                        </Button>
                        {/*</div>*/}
                      </AccordionContent>
                    </AccordionPanel>
                  )
                ) || <></>}
              </Accordion>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};
