import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { RouterState } from 'redux-first-history';
import { useSelector } from 'react-redux';
import { currentRouteSelector } from './store/store';
import React from 'react';
import { LoginPage } from './pages/Auth/LoginPage';
import { PropertiesPage } from './pages/Property/PropertiesPage';
import { ClaimsPage } from './pages/Claim/ClaimsPage';
import { SplashPage } from './pages/SplashPage';
import { AuthGuard } from './components/Auth/AuthGuard';
import { GuestGuard } from './components/Auth/GuestGuard';
import { userCheckSelector } from './store/slices/RuntimeSlice';
import { UnitPage } from './pages/Unit/UnitPage';
import { MainLayout } from './layout/MainLayout';
import { ErrorBoundary } from './components/ErrorBoundary';
import { t } from 'i18next';
import { CreateClaimPage } from './pages/Claim/CreateClaimPage';
import { ClaimPreviewPage } from './pages/Claim/ClaimPreviewPage';
import { StatisticsPage } from './pages/Statistics/StatisticsPage';

export const AppRouter: React.FC = () => {
  const { location }: RouterState = useSelector(currentRouteSelector);
  const userChecks = useSelector(userCheckSelector);
  return !userChecks ? (
    <SplashPage />
  ) : (
    <ErrorBoundary fallback={<p>{t('Something went wrong. Trying to fix the issue...')}</p>}>
      <Routes location={location as Partial<Location>}>
        <Route
          element={
            <MainLayout>
              <GuestGuard>
                <Outlet />
              </GuestGuard>
            </MainLayout>
          }
        >
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route
          element={
            <MainLayout>
              <AuthGuard>
                <Outlet />
              </AuthGuard>
            </MainLayout>
          }
        >
          <Route path="/properties/:propertyId?" element={<PropertiesPage />} />
          <Route path="/unit/:propertyId/:id/:areaId?" element={<UnitPage />} />
          <Route path="/claims/:propertyId?" element={<ClaimsPage />} />
          <Route path="/claims/:propertyId/step-1" element={<CreateClaimPage />} />
          {/*<Route path="/claims/edit/:propertyId/:claimId/step-1" element={<CreateClaimPage />} />*/}
          <Route path="/claims/:propertyId/step-2/:units" element={<ClaimPreviewPage />} />
          <Route path="/claims/edit/:propertyId/:claimId" element={<ClaimPreviewPage />} />
          <Route path="/statistics" element={<StatisticsPage />} />
        </Route>

        <Route path="/claim-print/:propertyId/:claimId/:token" element={<ClaimPreviewPage />} />
        <Route path="*" element={<Navigate to={'/properties'} replace />} />
      </Routes>
    </ErrorBoundary>
  );
};
