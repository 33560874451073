import React from 'react';
import { ReactComponent as BackIcon } from '../../assets/svg/back.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import { useTranslation } from 'react-i18next';
import { PropertyDto, UnitDto } from '../../store/api/generated/Api';
import moment from 'moment';
import { ReactComponent as VerifiedIcon } from '../../assets/svg/verified.svg';
import { ReactComponent as WorkInProgressIcon } from '../../assets/svg/work-in-progress.svg';
import { ReactComponent as SkippingIcon } from '../../assets/svg/skipped.svg';

interface UnitInfoProps {
  unit?: UnitDto;
  lastUpdatedBy?: string;
  lastUpdatedAt?: string;
  damageCount?: number;
  property?: PropertyDto;
  goBack?: () => void;
}

export const UnitInfo: React.FC<UnitInfoProps> = ({
  unit,
  property,
  lastUpdatedBy,
  lastUpdatedAt,
  damageCount,
  goBack,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between">
      <div className="flex min-h-[5rem] flex-col gap-5">
        <div className="flex gap-2">
          <BackIcon
            className="relative h-5 w-5 cursor-pointer select-none fill-zinc-600 stroke-zinc-600"
            onClick={goBack}
          />
          <div className="flex flex-col gap-1">
            <div className="flex items-start justify-center gap-8">
              <div className="flex flex-col gap-3">
                <div className="text-2xl font-semibold leading-7 text-zinc-700">
                  {t('Unit {{name}}', { name: unit?.number })}
                </div>
                <div className="font-normal leading-tight text-zinc-600">{property?.address}</div>
              </div>
              <div className="flex flex-col gap-2 lg:flex-row">
                <div className="h-6 whitespace-nowrap rounded-2xl bg-zinc-100 px-2.5 py-[0.2rem] text-sm font-semibold leading-tight text-zinc-700">
                  {t('{{damageCount}} damages', { damageCount: damageCount || 0 })}
                </div>
                {unit?.status === 'verified' && (
                  <div className="flex items-center gap-1 rounded-2xl bg-green-100 py-0.5 pl-2 pr-2.5 mix-blend-multiply">
                    <VerifiedIcon className="h-4 w-4 fill-green-600 stroke-green-600" />
                    <p className="text-center text-sm font-semibold not-italic text-green-600">
                      {t('Inspected')}
                    </p>
                  </div>
                )}
                {unit?.status === 'skipped' && (
                  <div className="flex items-center gap-1 rounded-2xl bg-yellow-100 py-0.5 pl-2 pr-2.5 mix-blend-multiply">
                    <SkippingIcon className="h-4 w-4 fill-yellow-400 stroke-yellow-400" />
                    <p className="text-center text-sm font-semibold not-italic text-yellow-400">
                      {t('Skipped')}
                    </p>
                  </div>
                )}
                {unit?.status === 'in_progress' && (
                  <div className="flex items-center gap-1 rounded-2xl bg-zinc-100 py-0.5 pl-2 pr-2.5 mix-blend-multiply">
                    <WorkInProgressIcon className="h-4 w-4 fill-zinc-800 stroke-zinc-800" />
                    <p className="whitespace-nowrap text-center text-sm font-semibold not-italic text-zinc-800">
                      {t('In progress')}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mr-1 mt-1.5 flex text-sm font-semibold leading-tight text-zinc-600">
        <span className="hidden lg:inline">
          {!!lastUpdatedAt &&
            !!lastUpdatedBy &&
            t('Last update by {{name}} on {{date}}', {
              date: lastUpdatedAt ? moment(lastUpdatedAt).format('YYYY/MM/DD') : '',
              name: lastUpdatedBy,
            })}
        </span>
        <CloseIcon
          className="ml-6 h-4 w-4 cursor-pointer select-none fill-zinc-600 stroke-zinc-600"
          onClick={goBack}
        />
      </div>
    </div>
  );
};
