import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentRouteSelector } from '../store/store';

export const ScrollToTop: React.FC = () => {
  const { location } = useSelector(currentRouteSelector);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};
