import React from 'react';
import { useSelector } from 'react-redux';
import { isApiLoadingSelector } from '../store/store';
import { Loading } from '../components/Loading';
import classNames from 'classnames';
import { loadingSelector } from '../store/slices/RuntimeSlice';

export const MainLayout: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const isLoading = useSelector(isApiLoadingSelector);
  const isLoadingManually = useSelector(loadingSelector);
  return (
    <div className="relative">
      <div
        className={classNames([
          'h-fit min-h-screen w-full',
          isLoading || isLoadingManually ? 'blur-sm' : '',
        ])}
      >
        {children}
      </div>
      {(isLoading || isLoadingManually) && <Loading />}
    </div>
  );
};
