import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import emptyImg from '../../assets/img/empty.png';
import { ReactComponent as CloseIcon } from '../../assets/svg/close-boxed.svg';
import { ReactComponent as SkipIcon } from '../../assets/svg/double-chevron-right.svg';
import { ReactComponent as AddIcon } from '../../assets/svg/plus-boxed.svg';
import { ReactComponent as CameraPlusIcon } from '../../assets/svg/camera-plus.svg';
import { ReactComponent as TrashIcon } from '../../assets/svg/trash.svg';
import { ReactComponent as MediumIcon } from '../../assets/svg/damage-medium.svg';
import { ReactComponent as HighIcon } from '../../assets/svg/damage-high.svg';
import {
  AreaDto,
  InspectionAssetDto,
  InspectionAssetImageDto,
  useInspectionAssetControllerDeleteInspectionImageMutation,
  useInspectionAssetControllerGetDamageTypesQuery,
  useInspectionAssetControllerPatchInspectionAssetMutation,
} from '../../store/api/generated/Api';
import classNames from 'classnames';
import { usePrevious } from '../../util/usePreviousHook';
import { Camera, CameraResultType } from '@capacitor/camera';
import { apiUrl } from '../../util/apiUrl';
import { ImagesLightbox } from './ImagesLightbox';

interface DamageEditProps {
  area?: AreaDto;
  damage?: InspectionAssetDto;
  addDamage: () => void;
  setDamage: (damage: InspectionAssetDto) => void;
  updateAreaStatus: (status: 'verified' | 'skipped') => void;
  className?: string;
}

export const DamageEdit: React.FC<DamageEditProps> = ({
  area,
  damage,
  setDamage,
  addDamage,
  updateAreaStatus,
  className,
}) => {
  const { t } = useTranslation();
  const prevDamage = usePrevious(damage);
  const { data: damageTypes } = useInspectionAssetControllerGetDamageTypesQuery();
  const [selectedDamageType, setSelectedDamageType] = useState(damage?.damageType?.toLowerCase());
  const [selectedDamageLevel, setSelectedDamageLevel] = useState<string | undefined>(
    damage?.damageLevel
  );
  const [comments, setComments] = useState(damage?.comments || '');
  const [otherType, setOtherType] = useState<string | undefined>(damage?.damageType);
  const [imagesModalOpen, setImagesModalOpen] = useState({ open: false, initialIndex: 0 });

  const [updateDamage] = useInspectionAssetControllerPatchInspectionAssetMutation();
  const [removeDamageImage] = useInspectionAssetControllerDeleteInspectionImageMutation();

  useEffect(() => {
    if (damageTypes && damage?.damageType) {
      setOtherType(
        damageTypes.find((type) => type.value === damage?.damageType) ? '' : damage?.damageType
      );
    }

    if (damageTypes && prevDamage?.damageType !== damage?.damageType) {
      setSelectedDamageType(
        damageTypes.find((type) => type.value === damage?.damageType)
          ? damage?.damageType?.toLowerCase()
          : damage?.damageType
          ? damageTypes[damageTypes.length - 1].value
          : ''
      );
    }

    if (prevDamage?.damageLevel !== damage?.damageLevel) {
      setSelectedDamageLevel(damage?.damageLevel?.toLowerCase());
    }

    if (prevDamage?.comments !== damage?.comments) {
      setComments(damage?.comments || '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [damage, damageTypes]);

  const doUpdate = async (type: 'damageType' | 'damageLevel' | 'comments', value?: string) => {
    if (damage) {
      let newDamage: InspectionAssetDto | undefined;
      switch (type) {
        case 'damageType':
          const damageType = value || selectedDamageType;

          if (damage.damageType !== damageType) {
            newDamage = await updateDamage({
              inspectionAssetId: damage.id,
              updateInspectionAssetDto: {
                damageType: damageTypes?.find((type) => type.value === damageType)
                  ? damageType
                  : otherType,
              },
            }).unwrap();
          }
          break;
        case 'damageLevel':
          const damageLevel = value || selectedDamageLevel;
          if (damage.damageLevel !== damageLevel) {
            newDamage = await updateDamage({
              inspectionAssetId: damage.id,
              updateInspectionAssetDto: {
                damageLevel,
              },
            }).unwrap();
          }
          break;
        case 'comments':
          const newComments = value || comments;
          if (damage.comments !== newComments) {
            newDamage = await updateDamage({
              inspectionAssetId: damage.id,
              updateInspectionAssetDto: {
                comments: newComments,
              },
            }).unwrap();
          }
          break;
      }
      if (newDamage) {
        setDamage(newDamage);
      }
    }
  };

  const addImage = async () => {
    if (damage) {
      try {
        const images = await Camera.getPhoto({
          allowEditing: false,
          correctOrientation: true,
          // webUseInput: process.env.NODE_ENV === 'development',
          presentationStyle: 'fullscreen',
          resultType: CameraResultType.Uri,
        });

        const files = await Promise.all(
          images
            .map((image) => image.webPath || '')
            .filter((image) => !!image)
            .map(async (imageUrl) => await (await fetch(imageUrl)).blob())
        );

        if (files.length) {
          const addedDamage = await updateDamage({
            inspectionAssetId: damage.id,
            updateInspectionAssetDto: {
              files,
            },
          }).unwrap();

          setDamage(addedDamage);
        }
      } catch (e) {
        // User canceled or did not allow all permissions
      }
    }
  };

  const removeImage = async (image: InspectionAssetImageDto) => {
    if (damage) {
      try {
        await removeDamageImage({
          inspectionAssetImageId: image.id,
        }).unwrap();

        setDamage({
          ...damage,
          inspectionImages: damage.inspectionImages.filter(({ id }) => id !== image.id),
        });
      } catch (e) {
        // User canceled or did not allow all permissions
      }
    }
  };

  const onUpdateDamageLevel = (level: 'low' | 'medium' | 'high') => {
    return async () => {
      setSelectedDamageLevel(level);
      await doUpdate('damageLevel', level);
    };
  };
  const onUpdateDamageType = (type: string) => {
    return async () => {
      setSelectedDamageType(type);

      await doUpdate('damageType', type);
    };
  };

  return !damage ? (
    <div
      className={classNames([
        'flex flex-1 flex-col items-center justify-center gap-5 self-stretch rounded-lg bg-zinc-50 p-6',
        className,
      ])}
    >
      <img src={emptyImg} className="relative w-44" alt="empty" />
      <div className="flex flex-col items-center justify-start gap-0.5 self-stretch">
        <div className="self-stretch text-center text-xl font-semibold leading-loose text-zinc-700">
          {area ? t('No Damages found') : t('Please select a room')}
        </div>
        {!!area && (
          <div className="self-stretch text-center font-normal leading-normal text-zinc-600">
            {t('Please Select the box “Add Damage” above')}
          </div>
        )}
        {area && (
          <div className="mt-10 flex w-[25.375rem] max-w-[calc(100vw-5rem)] flex-col items-start justify-start gap-3">
            <div
              onClick={addDamage}
              className="flex h-11 flex-1 cursor-pointer select-none items-center justify-center gap-2 self-stretch rounded-[2.875rem] border border-green-500 bg-green-500 px-[1.125rem] py-2.5 shadow"
            >
              <AddIcon className="relative h-5 w-5 fill-white stroke-white" />
              <div className="font-semibold leading-normal text-white">{t('Add damage')}</div>
            </div>
            <div className="flex items-start justify-start gap-3 self-stretch">
              <div
                onClick={() => updateAreaStatus('verified')}
                className="flex flex-1 cursor-pointer select-none items-center justify-center gap-2 self-stretch rounded-[2.875rem] border border-zinc-400 bg-white px-1 py-2.5 shadow"
              >
                <CloseIcon className="relative h-5 w-5 fill-zinc-700 stroke-zinc-700" />
                <div className="font-semibold leading-normal text-zinc-600">{t('No damage')}</div>
              </div>
              <div
                onClick={() => updateAreaStatus('skipped')}
                className="flex flex-1 cursor-pointer select-none items-center justify-center gap-2 self-stretch rounded-[2.875rem] border border-zinc-400 bg-white px-1 py-2.5 shadow"
              >
                <div className="font-semibold leading-normal text-zinc-600">{t('Skip')}</div>
                <SkipIcon className="relative m-1.5 h-3.5 w-3.5 fill-zinc-700 stroke-zinc-700" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      className={classNames([
        'flex flex-col items-start justify-start gap-5 rounded-lg py-6 lg:bg-zinc-50 lg:px-6',
        className,
      ])}
    >
      <div className="self-stretch border-zinc-200 pb-5 text-2xl font-semibold leading-7 text-zinc-700 lg:border-b">
        {t('Damage')}
      </div>
      <div className="flex flex-col items-start justify-start gap-5 self-stretch border-b border-zinc-200 pb-5">
        <div className="flex items-center justify-start self-stretch">
          <div className="shrink grow basis-0 text-sm font-medium leading-tight text-zinc-600">
            {t('Photos')}
          </div>
          <div
            onClick={addImage}
            className="flex cursor-pointer select-none items-center justify-center gap-2 rounded-[30px] border border-zinc-400 bg-white px-3.5 py-2 shadow"
          >
            <CameraPlusIcon className="relative h-5 w-5 fill-green-500 stroke-green-500" />
            <div className="text-sm font-semibold leading-tight text-zinc-600">
              {t('Add photo(s)')}
            </div>
          </div>
        </div>
        <div className="-m-1.5 flex flex-wrap items-start justify-start">
          {damage?.inspectionImages.map((image, i) => (
            <div className="p-1.5" key={i}>
              <div className="relative flex h-24 items-center justify-center overflow-hidden rounded-lg lg:h-[10.57rem] lg:w-fit  lg:min-w-[5rem] ">
                <img
                  className="h-full cursor-pointer"
                  src={apiUrl(image.thumbnailUrl)}
                  alt="damage"
                  onClick={() => setImagesModalOpen({ open: true, initialIndex: i })}
                />
                <div className="absolute right-3 top-3 cursor-pointer items-center justify-center rounded-lg border border-zinc-400 bg-white p-2 shadow">
                  <TrashIcon
                    onClick={() => removeImage(image)}
                    className="relative h-5 w-5 fill-zinc-500 stroke-zinc-500"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ImagesLightbox
        images={damage?.inspectionImages.map((image) => apiUrl(image.imageUrl))}
        isOpen={imagesModalOpen.open}
        startIndex={imagesModalOpen.initialIndex}
        setIsOpen={(isOpen) => setImagesModalOpen({ open: isOpen, initialIndex: 0 })}
      />
      <div className="flex w-full flex-col gap-4 border-b border-zinc-200 pb-5">
        <div className="text-sm font-medium leading-tight text-zinc-600">{t('Type')}</div>
        <div className="-mx-2 -my-[0.394rem] flex min-w-full flex-wrap items-center justify-start ">
          {damageTypes?.map(({ label, value }, index) => (
            <div
              key={value}
              className={classNames(
                [' cursor-pointer select-none px-2 py-[0.394rem] lg:w-1/4'],
                index === damageTypes.length - 1 ? 'lg: w-full' : 'w-1/2'
              )}
              onClick={onUpdateDamageType(value)}
            >
              <div
                className={classNames([
                  'flex h-10 w-full items-center justify-center gap-2 rounded-[1.875rem] border px-4 py-2.5 shadow',
                  selectedDamageType === value
                    ? 'border-green-500 bg-green-500'
                    : 'border-zinc-400 bg-white',
                ])}
              >
                {value && (
                  <img
                    src={`/assets/svg/damage-types${
                      selectedDamageType === value ? '/selected' : ''
                    }/${value}.svg`}
                    className="h-5 w-5"
                    alt="damage-type"
                  />
                )}
                <div
                  className={classNames([
                    'text-sm font-semibold leading-tight',
                    selectedDamageType === value ? 'text-white' : 'text-zinc-600',
                  ])}
                >
                  {label}
                </div>
              </div>
            </div>
          ))}
          {!!damageTypes && damageTypes[damageTypes.length - 1].value === selectedDamageType && (
            <input
              className="mx-2 my-[0.394rem] box-border w-full cursor-pointer select-none rounded-lg border border-gray-300 px-3 py-2 text-zinc-500 shadow focus:outline-blue-500  lg:mr-0 lg:w-[calc(75%-1rem)]"
              placeholder={t('Other damage types')}
              value={otherType}
              onInput={(e) => setOtherType((e.target as HTMLTextAreaElement).value)}
              onBlur={(e) => {
                doUpdate('damageType', e.target.value);
              }}
            />
          )}
        </div>
      </div>
      <div className="w-full border-b border-zinc-200 pb-5">
        <div className="left-0 top-0 mb-5 flex h-5 w-full flex-col items-start justify-start">
          <div className="text-sm font-medium leading-tight text-zinc-600">{t('Severity')}</div>
        </div>
        <div className="flex w-full items-start justify-start overflow-hidden rounded-lg border border-zinc-400 xl:w-84">
          {/*<div*/}
          {/*  onClick={onUpdateDamageLevel('low')}*/}
          {/*  className={classNames([*/}
          {/*    'flex h-10 shrink grow basis-0 cursor-pointer select-none items-center justify-center gap-2 border-r border-zinc-400',*/}
          {/*    selectedDamageLevel === 'low' ? 'bg-green-100' : 'bg-white',*/}
          {/*  ])}*/}
          {/*>*/}
          {/*  <LowIcon className="h-5 w-5 fill-green-500 stroke-green-500" />*/}
          {/*  <div className="text-sm font-semibold leading-tight text-green-600">*/}
          {/*    <span className="hidden lg:inline">{t('No damage')}</span>*/}
          {/*    <span className="inline lg:hidden">{t('None')}</span>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div
            onClick={onUpdateDamageLevel('medium')}
            className={classNames([
              'flex h-10 shrink grow basis-0 cursor-pointer select-none items-center justify-center gap-2',
              selectedDamageLevel === 'medium' ? 'bg-yellow-100' : 'bg-white',
            ])}
          >
            <MediumIcon className="h-5 w-5 fill-yellow-300 stroke-yellow-300" />
            <div className="text-sm font-semibold leading-tight text-yellow-400">
              <span className="hidden lg:inline">{t('Mild damage')}</span>
              <span className="inline lg:hidden">{t('Mild')}</span>
            </div>
          </div>
          <div
            onClick={onUpdateDamageLevel('high')}
            className={classNames([
              'flex h-10 shrink grow basis-0 cursor-pointer select-none items-center justify-center gap-2 border-l border-zinc-400',
              selectedDamageLevel === 'high' ? 'bg-red-100' : 'bg-white',
            ])}
          >
            <HighIcon className="h-5 w-5 fill-red-400 stroke-red-400" />
            <div className="text-sm font-semibold leading-tight text-red-600">
              <span className="hidden lg:inline">{t('Heavy damage')}</span>
              <span className="inline lg:hidden">{t('Heavy')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start gap-4 self-stretch">
        <div className="self-stretch text-sm font-medium leading-tight text-zinc-600">
          {t('Comments')}
        </div>
        <div className="flex flex-col items-start justify-start gap-1.5 self-stretch">
          <textarea
            className="w-full rounded-lg border border-zinc-400 bg-white px-3.5 py-3 text-zinc-500 shadow"
            rows={6}
            value={comments}
            onInput={(e) => setComments((e.target as HTMLTextAreaElement).value)}
            onBlur={(e) => doUpdate('comments', e.target.value)}
            placeholder={t('Add comments...')}
          ></textarea>
        </div>
      </div>
    </div>
  );
};
