import React, { useState } from 'react';
import { ReactComponent as LogoIcon } from '../../assets/svg/logo.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import LoginSide from '../../assets/img/login-side.jpg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store/store';
import { login } from '../../store/actions/AuthActions';

export const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const logIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await dispatch(
        login({
          loginDto: {
            email,
            password,
          },
        })
      ).unwrap();
    } catch (e: any) {
      if (e?.status === 404) {
        setErrorMessage(e?.data?.message);
      }
    }
  };

  return (
    <div className="flex h-screen w-full flex-1 items-start justify-center bg-white">
      <div
        className="bg-fill hidden h-full w-[calc(50%-2rem)] self-stretch bg-[length:auto_100%] bg-center bg-no-repeat lg:block"
        style={{
          backgroundImage: `url(${LoginSide})`,
        }}
      />
      <div className="flex h-full w-full items-center justify-center gap-2.5 self-stretch lg:w-[calc(50%+2rem)]">
        <div className="flex w-auto flex-col items-center justify-start gap-8 px-4">
          <LogoIcon className="h-7 w-24 fill-green-500 stroke-green-500" />
          <div className="flex flex-col items-center justify-start gap-8 self-stretch">
            <div className="flex flex-col items-start justify-start gap-2 self-stretch">
              <div className="self-stretch text-center text-2xl font-semibold leading-loose text-zinc-700">
                {t('Log in to your account')}
              </div>
              <div className="self-stretch text-center font-normal leading-normal text-zinc-600">
                {t('Welcome back! Please enter your details.')}
              </div>
            </div>
          </div>
          {errorMessage && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <strong className="font-bold">{t('Error: ')}</strong>
              <span className="block sm:inline">{errorMessage}</span>
              <span
                className="absolute bottom-0 right-0 top-0 cursor-pointer select-none px-4 py-3"
                onClick={() => setErrorMessage('')}
              >
                <CloseIcon className="h-6 w-6 fill-current text-red-500" />
              </span>
            </div>
          )}
          <form
            onSubmit={logIn}
            className="mt-5 flex flex-col items-center justify-start gap-6 self-stretch rounded-xl"
            autoComplete="off"
          >
            <div className="flex flex-col items-start justify-start self-stretch rounded-lg  border border-zinc-400 bg-white shadow">
              <div className="flex w-auto items-center justify-start gap-2 self-stretch rounded-tl-lg rounded-tr-lg  border-b border-zinc-400 bg-white px-3.5">
                <input
                  value={email}
                  autoComplete="off"
                  onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
                  placeholder={t('Enter your email')}
                  type="email"
                  className="block w-full shrink grow basis-0 border-none p-2.5 font-normal leading-normal text-zinc-500 !outline-none !ring-transparent"
                />
              </div>
              <div className="flex items-center justify-start gap-2 self-stretch rounded-bl-lg rounded-br-lg bg-white px-3.5">
                <input
                  value={password}
                  autoComplete="off"
                  onInput={(e) => setPassword((e.target as HTMLInputElement).value)}
                  type="password"
                  className="block w-full shrink grow basis-0 border-none p-2.5 font-normal leading-normal text-zinc-500 !outline-none !ring-transparent"
                  placeholder={t('••••••••')}
                />
              </div>
            </div>
            <div className="mt-5 flex h-11 flex-col items-start justify-start gap-4 self-stretch">
              <input
                type="submit"
                className="flex cursor-pointer select-none items-center justify-center gap-2 self-stretch rounded-[2.875rem] border border-green-500 bg-green-500 px-[1.125rem] py-2.5 font-semibold leading-normal text-white shadow"
                value={t('Log in')}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
