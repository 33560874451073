import React, { useEffect, useState } from 'react';
import { Toast } from 'flowbite-react';
import { ReactComponent as ErrorIcon } from '../assets/svg/forbidden.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { apiErrorSelector } from '../store/store';
import { usePrevious } from '../util/usePreviousHook';
import { isEqual } from 'lodash-es';
import { ReactComponent as CloseIcon } from '../assets/svg/close.svg';
import {
  BadRequestResponseDto,
  ForbiddenResponseDto,
  InternalServerErrorResponseDto,
  NotFoundResponseDto,
  UnauthorizedResponseDto,
} from '../store/api/generated/Api';

interface Error {
  error: string;
  id: string;
  visible?: boolean;
}

type AnyError =
  | BadRequestResponseDto
  | UnauthorizedResponseDto
  | InternalServerErrorResponseDto
  | ForbiddenResponseDto
  | NotFoundResponseDto;

export const ServerErrors: React.FC = () => {
  const { t } = useTranslation();
  const serverErrors = useSelector(apiErrorSelector);
  const prevServerErrors = usePrevious(serverErrors);
  const [errors, setErrors] = useState<Error[]>([]);
  const statusMap: { [key: number]: string } = {
    400: t('Bad request'),
    401: t('Unauthenticated'),
    403: t('Unauthorized'),
    404: t('Not Found'),
    500: t('Server error'),
  };
  useEffect(() => {
    let newErrors = errors;
    if (!isEqual(prevServerErrors, serverErrors) && serverErrors && serverErrors.length) {
      newErrors = [
        ...errors,
        ...(serverErrors as any[])
          .map(
            ({
              error,
              requestId,
            }: {
              error: { data: AnyError; status: number };
              requestId: string;
            }) => ({
              id: requestId,
              error: `${statusMap[error.status] || t('Error')}: ${
                error?.data?.message || t('An error has occurred.')
              }`,
              visible: true,
            })
          )
          .filter(({ id }) => !errors.find((it) => it.id === id)),
      ];

      const visibleErrors = errors.filter((x) => x.visible);
      if (visibleErrors.length > 5) {
        visibleErrors[0].visible = false;
      }
      setErrors(newErrors);
    }

    const timeout = setTimeout(() => {
      setErrors(newErrors.map((x) => ({ ...x, visible: false })));
    }, 3000);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverErrors]);

  const hideToast = (errorId: string) => {
    setErrors(errors.map((error) => (error.id === errorId ? { ...error, visible: false } : error)));
  };

  return (
    <div className="fixed bottom-0 right-0 z-10 flex flex-col items-end justify-end gap-3 p-5">
      {errors.map(
        ({ error, id, visible }) =>
          visible && (
            <Toast key={id} className="!w-auto">
              <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-zinc-50 text-zinc-500">
                <ErrorIcon className="h-5 w-5 fill-red-600 stroke-red-600" />
              </div>
              <div className="ml-3 flex-1 text-sm font-normal text-zinc-600">
                {error || t('An error has occurred.')}
              </div>
              <CloseIcon
                className="ml-4 h-3 w-3 cursor-pointer select-none fill-zinc-600 stroke-zinc-600"
                onClick={() => hideToast(id)}
              />
            </Toast>
          )
      )}
    </div>
  );
};
