import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../components/table/Table';
import { TableRow } from '../../components/table/TableRow';
import { TableHead } from '../../components/table/TableHead';
import { TableHeadCell } from '../../components/table/TableHeadCell';
import { TableBody } from '../../components/table/TableBody';
import { TableCell } from '../../components/table/TableCell';
import { PaginationWithIcons } from '../../components/Pagination';
import { DropdownItems } from '../../components/Dropdown';
import { SidebarMenu } from '../../components/Sidebar';
import { push, replace } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import {
  Api,
  ClaimControllerGetClaimsForPropertyApiResponse,
  ClaimDto,
  PropertyDto,
  usePropertyControllerGetPropertiesQuery,
} from '../../store/api/generated/Api';
import { Accordion } from '../../components/accordion/Accordion';
import { AccordionPanel } from '../../components/accordion/AccordionPanel';
import { AccordionTitle } from '../../components/accordion/AccordionTitle';
import { AccordionContent } from '../../components/accordion/AccordionContent';
import { MobileHeader } from '../../components/MobileHeader';
import { Button, CustomFlowbiteTheme } from 'flowbite-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as DownloadIcon } from '../../assets/svg/file-download.svg';
import { useServerTable } from '../../util/useServerTable';
import { apiUrl } from '../../util/apiUrl';
import moment from 'moment';
import { useParams } from 'react-router-dom';

export const ClaimsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { propertyId } = useParams() as {
    propertyId: string;
  };

  const { data: properties } = usePropertyControllerGetPropertiesQuery({});
  const property = properties?.find(({ id }) => id === propertyId);

  const {
    currentPage,
    hasSorting,
    tableData,
    infiniteScrollItems,
    setCurrentPage,
    fetchMoreItems,
  } = useServerTable<ClaimDto, ClaimControllerGetClaimsForPropertyApiResponse>({
    fetchItems: useMemo(
      () =>
        async ({ sort, currentPage, search }) => {
          try {
            if (property) {
              return await dispatch(
                Api.endpoints.claimControllerGetClaimsForProperty.initiate(
                  {
                    pageSize: 15,
                    propertyId: property.id,
                    pageNumber: currentPage,
                    filter: sort?.column,
                    sort: sort?.direction,
                  },
                  { forceRefetch: true }
                )
              ).unwrap();
            }
          } catch (e) {}
          return {
            items: [],
            totalPages: 0,
            currentPage: 0,
            searchTerm: '',
            totalItems: 0,
          };
        },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [property]
    ),
    defaultSetValue: [],
  });

  const setProperty = (property: PropertyDto) => {
    dispatch(replace(`/claims/${property.id}`));
    setCurrentPage(1);
  };

  const onItemClick = (claimId: string) => {
    if (property) {
      dispatch(
        push(`/claims/edit/${encodeURIComponent(property?.id)}/${encodeURIComponent(claimId)}`)
      );
    }
  };

  useEffect(() => {
    if (!property && properties?.length) {
      setProperty(properties[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, property]);

  const buttonCustomTheme: CustomFlowbiteTheme['button'] = {
    base: 'h-10 bg-green-500 !rounded-full focus:outline-none mt-4',
    outline: {
      on: 'flex !p-3.5 justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white',
    },
    inner: {
      base: 'justify-center font-semibold',
    },
  };

  const buttonCustomTheme2: CustomFlowbiteTheme['button'] = {
    base: 'h-10 bg-white text-zinc-600 !rounded-full focus:outline-none mt-4',
    outline: {
      on: 'flex !p-3.5 justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white',
    },
    inner: {
      base: 'justify-center font-semibold',
    },
  };

  return (
    <div className="flex h-full w-full bg-white">
      <SidebarMenu />
      <div className="flex flex-1">
        <div className="relative z-0 mx-4 flex flex-1 flex-col px-4 lg:px-6">
          <MobileHeader />
          <div className="flex w-full flex-col justify-between gap-4 pt-2 lg:flex-row lg:items-center lg:gap-0 lg:pt-12 xl:pt-8">
            <div className="left-101 top-12 text-101 font-semibold text-zinc-950">
              {t('Claims')}
            </div>
            <div className="flex flex-1 flex-col-reverse gap-5 lg:ml-5 lg:flex-row lg:justify-end">
              <DropdownItems
                className="flex-1 lg:min-w-[12.2rem]"
                options={properties}
                value={property}
                onInput={setProperty}
                searchable={true}
              />
              {!!property && (
                <div
                  onClick={() =>
                    dispatch(push(`/claims/${encodeURIComponent(property.id)}/step-1`))
                  }
                  className="flex h-10 cursor-pointer select-none items-center justify-center gap-2 rounded-[30px] bg-green-500 px-4 py-2.5 text-sm font-semibold leading-tight text-white shadow"
                >
                  {t('Create Claim')}
                </div>
              )}
            </div>
          </div>

          <div className="my-5 hidden rounded-xl border border-zinc-50 bg-white pb-3 shadow-md sm:rounded-lg lg:block ">
            <Table hoverable className="border-b border-zinc-200 ">
              <TableHead>
                <TableHeadCell scope="col" {...hasSorting('name')}>
                  {t('Claim')}
                </TableHeadCell>
                <TableHeadCell scope="col" {...hasSorting('createdById')}>
                  {t('Date')}
                </TableHeadCell>
                <TableHeadCell scope="col"></TableHeadCell>
              </TableHead>
              <TableBody>
                {tableData.items?.map(({ id, name, claimReport, date }) => (
                  <TableRow className="cursor-pointer select-none" key={id}>
                    <TableCell className="!text-lg !font-medium" onClick={() => onItemClick(id)}>
                      {name}
                    </TableCell>
                    <TableCell onClick={() => onItemClick(id)}>
                      {date ? moment(date).format('LLL') : ''}
                    </TableCell>
                    <TableCell>
                      {!!claimReport?.claimReportUrl && (
                        <a href={apiUrl(claimReport.claimReportUrl)} download={`${name}.pdf`}>
                          <Button
                            className="flex h-10 w-10 items-center justify-center rounded-full"
                            theme={buttonCustomTheme}
                          >
                            <DownloadIcon className="h-5 w-5 stroke-white" />
                          </Button>
                        </a>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <PaginationWithIcons
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={tableData.totalPages}
            />
          </div>

          <div className="my-5 w-full lg:hidden">
            <InfiniteScroll
              next={fetchMoreItems}
              hasMore={currentPage < infiniteScrollItems.totalPages}
              loader={<></>}
              dataLength={(infiniteScrollItems.items?.length || 0) * 2}
            >
              <Accordion className="flex flex-col gap-3.5 border-none" collapseAll={true}>
                {infiniteScrollItems.items?.map(({ id, name }) => (
                  <AccordionPanel key={id}>
                    <AccordionTitle className="!text-lg !font-medium">
                      <p onClick={() => onItemClick(id)} className="!text-lg !font-medium">
                        {name}
                      </p>
                    </AccordionTitle>
                    <AccordionContent className="-mt-3.5 rounded-b-lg">
                      <div className="mb-3 flex flex-row justify-between text-zinc-800">
                        <p className="text-base font-medium not-italic text-zinc-800">
                          {t('Date')}
                        </p>
                        <p className="text-base font-normal not-italic text-zinc-600">
                          MISSING FROM API
                        </p>
                      </div>
                      {/*<div className="flex">*/}
                      <div className="flex gap-3">
                        {!!property && (
                          <Button
                            className="flex-1"
                            onClick={() => onItemClick(id)}
                            theme={buttonCustomTheme2}
                          >
                            {t('View')}
                          </Button>
                        )}
                        <Button className="flex-1" theme={buttonCustomTheme}>
                          {t('Download')}
                        </Button>
                      </div>
                      {/*</div>*/}
                    </AccordionContent>
                  </AccordionPanel>
                )) || <></>}
              </Accordion>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};
