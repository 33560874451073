import { useEffect, useState } from 'react';
import { SortDirection, TableHeadCellProps } from '../components/table/TableHeadCell';
import { useTailwindBreakpoint } from './tailwindBreakpointHook';
import { PagedResultDto } from '../store/api/generated/Api';

type SortType = { column: string; direction: SortDirection };

type UpdateItemsFunction<T> = (params: {
  currentPage: number;
  sort?: SortType;
  search?: string;
}) => Promise<T>;

export function useServerTable<
  ItemType,
  ApiResponse extends PagedResultDto & { items?: ItemType[] }
>({
  fetchItems,
  defaultSetValue,
}: {
  fetchItems: UpdateItemsFunction<ApiResponse>;
  defaultSetValue: ItemType[];
}) {
  const { isMobile } = useTailwindBreakpoint();
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<SortType | undefined>();
  const [currentPage, setCurrentPage] = useState(1);

  const [tableData, setTableData] = useState<ApiResponse>({
    items: defaultSetValue,
    totalPages: 0,
    currentPage: 0,
    searchTerm: '',
    totalItems: 0,
  } as ApiResponse);

  const [infiniteScrollItems, setInfiniteScrollItems] = useState<ApiResponse>({
    items: defaultSetValue,
    totalPages: 0,
    currentPage: 0,
    searchTerm: '',
    totalItems: 0,
  } as ApiResponse);

  const hasSorting = (column: string): TableHeadCellProps => ({
    sortable: true,
    sortDirection: sort?.column === column ? sort?.direction : undefined,
    setSortDirection: (direction: SortDirection) => {
      direction ? setSort({ column, direction }) : setSort(undefined);
    },
  });

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  // Debounce search terms
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearch(searchValue);
      setCurrentPage(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    const effectAsync = async () => {
      const data = await fetchItems({
        sort,
        search,
        currentPage,
      });

      if (isMobile) {
        setInfiniteScrollItems((prevState) => ({
          ...data,
          items:
            currentPage !== 1 ? [...(prevState.items || []), ...(data?.items || [])] : data.items,
        }));
        if (currentPage === 1) {
          setTableData(data);
        }
      } else {
        if (currentPage === 1) {
          setInfiniteScrollItems(data);
        }
        setTableData(data);
      }
    };
    effectAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchItems, currentPage, sort, search]);

  const fetchMoreItems = () => {
    if (isMobile) {
      setCurrentPage((prevVal) => prevVal + 1);
    }
  };

  return {
    fetchMoreItems,
    hasSorting,
    currentPage,
    sort,
    search,
    searchValue,
    setSearchValue,
    tableData,
    infiniteScrollItems,
    setCurrentPage,
  };
}
