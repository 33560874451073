/** @type {import('tailwindcss').Config.theme} */
module.exports = {
  extend: {
    colors: {
      green: {
        50: 'var(--green-50)',
        100: 'var(--green-100)',
        200: 'var(--green-200)',
        300: 'var(--green-300)',
        400: 'var(--green-400)',
        500: 'var(--green-500)',
        600: 'var(--green-600)',
        700: 'var(--green-700)',
        800: 'var(--green-800)',
        900: 'var(--green-900)',
        950: 'var(--green-950)',
      },
      zinc: {
        50: 'var(--zinc-50)',
        100: 'var(--zinc-100)',
        200: 'var(--zinc-200)',
        300: 'var(--zinc-300)',
        400: 'var(--zinc-400)',
        500: 'var(--zinc-500)',
        600: 'var(--zinc-600)',
        700: 'var(--zinc-700)',
        800: 'var(--zinc-800)',
        900: 'var(--zinc-900)',
        950: 'var(--zinc-950)',
      },
    },
    fontFamily: {
      'font-poppins': 'font-family: Poppins',
    },
    width: {
      84: '21.438rem',
      101: '55rem',
    },
    height: {
      101: '46.5rem',
      102: '34rem' /* 544px */,
    },
    spacing: {
      101: '13.4375rem' /* 215px */,
      102: '52.4375rem' /* 839px */,
      103: '33.375rem' /* 534px */,
      104: '24.4375rem' /* 391px */,
      105: '3.25rem' /* 52px */,
      106: '7.375rem' /* 118px */,
      107: '18.188rem' /* 291px */,
    },
    fontSize: {
      101: ['2rem'],
    },
    borderRadius: {
      101: '1.875rem' /* 30px */,
    },
    backgroundImage: {
      'no-image': `none`,
    },
    backgroundSize: {
      80: '80%',
    },
    flex: {
      1: 1,
    },
    zIndex: {
      1: 1,
      2: 2,
    },
  },
};
