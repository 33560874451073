import { CustomFlowbiteTheme, Sidebar, Avatar, Button } from 'flowbite-react';
import { ReactComponent as LogoIcon } from '../assets/svg/logo.svg';
import { ReactComponent as PropertyIcon } from '../assets/svg/property-icon.svg';
import { ReactComponent as ClaimIcon } from '../assets/svg/claim-icon.svg';
import { ReactComponent as LogoutIcon } from '../assets/svg/logout-icon.svg';
import { ReactComponent as CloseIcon } from '../assets/svg/close.svg';
import { ReactComponent as BugIcon } from '../assets/svg/bug.svg';
import { ReactComponent as StatisticsIcon } from '../assets/svg/statistics.svg';
import { useSelector } from 'react-redux';
import { userSelector } from '../store/slices/AuthSlice';
import { currentRouteSelector, useAppDispatch } from '../store/store';
import { push, RouterState } from 'redux-first-history';
import classNames from 'classnames';
import { mobileMenuOpenSelector, setMobileMenuOpen } from '../store/slices/RuntimeSlice';
import { useTranslation } from 'react-i18next';
import { logout } from '../store/actions/AuthActions';

export const customTheme: CustomFlowbiteTheme['sidebar'] = {
  root: {
    base: 'h-full lg:h-screen fixed lg:sticky lg:top-0 lg:left-0 z-10 lg:z-0 transition-all ease-in-out duration-500',
    inner:
      'flex h-full lg:h-screen absolute xl:w-full shrink grow basis-0 flex-col items-start justify-between self-stretch p-[0px]',
    collapsed: {
      off: 'w-48 bg-white xl:w-72 shadow border-r border-zinc-100',
      on: 'w-48 bg-white xl:w-72 shadow border-r border-zinc-100',
    },
  },
  collapse: {
    button:
      'group flex w-full items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700',
    icon: {
      base: 'h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white',
      open: {
        off: '',
        on: 'text-gray-900',
      },
    },
    label: {
      base: 'ml-3 flex-1 whitespace-nowrap text-left',
      icon: 'h-6 w-6',
    },
    list: 'space-y-2 py-2',
  },
  logo: {
    img: 'hidden',
  },
  item: {
    base: 'flex select-none cursor-pointer items-center justify-center rounded-3xl p-2 text-sm font-medium text-zinc-800 hover:bg-zinc-50 dark:text-white dark:hover:bg-zinc-70',
    active: 'bg-zinc-50 dark:bg-zinc-500',
    icon: {
      base: 'h-5 w-5 fill-zinc-500 stroke-zinc-500',
      active: 'fill-zinc-500 stroke-zinc-500',
    },
  },
};

export const buttonCustomTheme: CustomFlowbiteTheme['button'] = {
  base: 'lg:hidden h-10 w-10 outline-none focus:outline-none mt-4',
  outline: {
    on: 'flex !p-3.5 justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white',
  },
};

export interface MenuItems {
  name: string;
  route: string;
  icon: any;
}

export const SidebarMenu = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector);
  const menuItems: Array<MenuItems> = [
    { name: 'Properties', route: '/properties', icon: PropertyIcon },
    { name: 'Claims', route: '/claims', icon: ClaimIcon },
    { name: 'Statistics', route: '/statistics', icon: StatisticsIcon },
  ];
  const { location }: RouterState = useSelector(currentRouteSelector);
  const mobileMenuOpen = useSelector(mobileMenuOpenSelector);

  return (
    <>
      <Sidebar
        theme={customTheme}
        className={classNames([mobileMenuOpen ? 'right-0' : '-right-48'])}
      >
        <div className="flex w-full flex-row justify-between">
          <Sidebar.Logo href="" img={''}>
            <LogoIcon className="ml-0 mt-4 h-14 w-24 fill-green-500 stroke-green-500 xl:h-20 xl:w-36" />
          </Sidebar.Logo>

          <Button
            outline
            theme={buttonCustomTheme}
            onClick={() => dispatch(setMobileMenuOpen(false))}
            className="after:content"
          >
            <CloseIcon className="h-6 w-6 fill-zinc-500 stroke-zinc-500" />
          </Button>
        </div>

        <Sidebar.Items className="flex flex-1 flex-col overflow-hidden px-2 xl:w-64">
          <Sidebar.ItemGroup>
            {menuItems.map((item, index) => {
              return (
                <Sidebar.Item
                  theme={customTheme?.item}
                  icon={item.icon}
                  key={index}
                  active={location?.pathname === item.route}
                  onClick={() => {
                    dispatch(push(item.route));
                    dispatch(setMobileMenuOpen(false));
                  }}
                >
                  <p>{item.name}</p>
                </Sidebar.Item>
              );
            })}
            <Sidebar.Item
              icon={BugIcon}
              theme={customTheme?.item}
              href="https://forms.gle/dCYtTwaHBvUyPLjLA"
              target="_blank"
            >
              <p>{t('Report a bug')}</p>
            </Sidebar.Item>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup className="mb-5 mt-auto">
            <Sidebar.Item theme={customTheme?.item}>
              <Avatar
                rounded
                className="whitespace-break-spaces text-center text-[14px] font-semibold leading-tight text-neutral-600"
              >
                {user?.firstName} {user?.lastName}
              </Avatar>
            </Sidebar.Item>
            <Sidebar.Item
              theme={customTheme?.item}
              className="ml-6"
              icon={LogoutIcon}
              onClick={() => dispatch(logout())}
            >
              <p>Log out</p>
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
      <div
        className={classNames([
          'absolute top-0 z-2 bg-black opacity-50 lg:hidden',
          mobileMenuOpen ? 'left-0 h-full w-screen' : 'hidden',
        ])}
        onClick={() => dispatch(setMobileMenuOpen(false))}
      ></div>
    </>
  );
};
