import React from 'react';
import { CustomFlowbiteTheme, Pagination } from 'flowbite-react';

export const customTheme: CustomFlowbiteTheme['pagination'] = {
  pages: {
    base: 'xs:mt-0 mt-2 flex justify-center items-center pl-6 pr-6 pt-2',
    previous: {
      base: 'ml-0 mr-0 rounded-l-lg border border-gray-300 bg-white py-2 px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white',
      icon: 'h-5 w-5',
    },
    next: {
      base: 'rounded-r-lg border border-gray-300 bg-white py-2 px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white',
      icon: 'h-5 w-5',
    },
    selector: {
      base: 'rounded-full w-12 bg-white py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white',
      active:
        '!bg-cyan-150 text-zinc-600 hover:bg-green-100 hover:text-green-700 dark:border-zinc-700 dark:!bg-zinc-700 dark:text-white rounded-full',
      disabled: 'opacity-50 cursor-normal',
    },
  },
};

interface PaginationWithIconsProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
}

export const PaginationWithIcons: React.FC<PaginationWithIconsProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  return (
    <Pagination
      className="pagination"
      theme={customTheme}
      currentPage={currentPage}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
      showIcons
      totalPages={totalPages}
    />
  );
};
