import { useEffect, useState } from 'react';

export function useResizeTrigger() {
  const [resizeTrigger, setResizeTrigger] = useState(false);
  useEffect(() => {
    const onResize = () => {
      setResizeTrigger(true);
      setTimeout(() => setResizeTrigger(false));
    };
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);
  return { resizeTrigger };
}
