import loading from '../assets/lottie/loading.json';
import Lottie from 'lottie-react';
import React from 'react';

export const Loading: React.FC = () => {
  return (
    <div className="absolute bottom-0 left-0 top-0 flex h-screen w-screen items-center justify-center">
      <Lottie className="h-48 w-48" animationData={loading} loop={true} />
    </div>
  );
};
