import React from 'react';
import { AccordionTitleProps as Core } from 'flowbite-react/lib/esm/components/Accordion/AccordionTitle';
import { Accordion } from 'flowbite-react';
import { accordionCustomTheme } from './Accordion';

interface AccordionTitleProps {}

export const AccordionTitle: React.FC<AccordionTitleProps & Core> = (props) => {
  return <Accordion.Title {...props} theme={accordionCustomTheme?.title} />;
};
