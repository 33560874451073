import { CustomFlowbiteTheme, Accordion as DefaultAccordion } from 'flowbite-react';
import React from 'react';
import { InspectionAssetDto, UnitDto } from '../../store/api/generated/Api';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { ClaimDamage } from './ClaimDamage';
interface AccordionProps {
  options?: UnitDto;
  setDamage: (damage: InspectionAssetDto) => void;
  className?: string;
}

export const accordionCustomTheme: CustomFlowbiteTheme['accordion'] = {
  root: {
    base: '!mb-5',
    flush: {
      off: 'rounded-lg border-none',
      on: 'border-none',
    },
  },
  content: {
    base: 'px-3.5 py-2.5 last:rounded-b-lg bg-zinc-50 !border-none',
  },
  title: {
    arrow: {
      base: 'h-9 w-9 shrink-0 border-2 rounded-full fill-zinc-500 -mr-2.5 -mt-0.5 print:hidden',
      open: {
        off: '',
        on: 'rotate-180',
      },
    },
    flush: {
      off: 'bg-white',
      on: 'hover:bg-white',
    },
    base: 'flex w-full !bg-white items-center justify-between py-2 lg:pr-2.5 print:pr-2.5 text-left text-zinc-950 font-medium dark:text-gray-400 focus:ring-0',
    open: {
      off: 'bg-white rounded-lg',
      on: 'bg-zinc-50 rounded-t-lg',
    },
  },
};

export const ClaimAccordion: React.FC<AccordionProps> = ({ options, setDamage }) => {
  const { t } = useTranslation();

  return (
    <DefaultAccordion collapseAll theme={accordionCustomTheme} className=" divide-none border-none">
      <DefaultAccordion.Panel className="!border-none">
        <DefaultAccordion.Title theme={accordionCustomTheme.title}>
          <div className="flex w-full flex-col print:flex-row print:justify-between print:space-x-14 lg:flex-row lg:justify-between lg:space-x-14">
            <div className="flex flex-1 items-end gap-5 print:items-center lg:items-center">
              <p className="text-3xl font-semibold text-zinc-900">{options?.number}</p>
              <hr className="mb-2 flex-1 bg-gray-200" />
            </div>
            <p className="mb-2 flex text-sm font-normal text-zinc-600 print:items-center print:text-right lg:items-center lg:text-right">
              {t('Last update {{date}}', {
                date: moment(options?.lastUpdatedAt).format('YYYY/MM/DD'),
              })}
            </p>
          </div>
        </DefaultAccordion.Title>
        <DefaultAccordion.Content className="!px-0 pt-3 print:!block">
          <div className="flex h-fit w-full flex-col items-start justify-start gap-6 pb-8 print:px-6 lg:px-6">
            <div className="flex h-auto flex-col gap-6 self-stretch">
              {options?.areas
                .filter(({ inspectionAssets }) => !!inspectionAssets.length)
                .map((area) => (
                  <div key={area.id} className="flex flex-col gap-4">
                    <div className="text-2xl font-semibold leading-[30px] text-zinc-700">
                      {area.name} {area.number}
                    </div>
                    <div className="relative flex flex-col gap-4">
                      <div className="absolute bottom-0 left-0 top-0 hidden w-0 border border-zinc-200 print:block lg:block"></div>
                      {area.inspectionAssets.map((damage) => (
                        <ClaimDamage key={damage.id} damage={damage} setDamage={setDamage} />
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </DefaultAccordion.Content>
      </DefaultAccordion.Panel>
    </DefaultAccordion>
  );
};
