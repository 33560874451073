import React from 'react';
import Lottie from 'lottie-react';
import splashHouseCocoon from '../assets/lottie/splash_house_cocoon.json';
import { ReactComponent as LogoIcon } from '../assets/svg/logo.svg';

export const SplashPage: React.FC = () => {
  return (
    <div className="flex h-full w-full items-center justify-center bg-white">
      <div className="flex w-[343px] max-w-full flex-col items-center justify-start gap-16">
        <div className="mt-[87px] flex flex-col items-start justify-start gap-8">
          <div className="flex flex-col items-center justify-start gap-4">
            <Lottie className="w-[80%]" animationData={splashHouseCocoon} loop={true} />
            <LogoIcon className="h-7 w-24 fill-green-500 stroke-green-500"></LogoIcon>
            <div className="text-center text-[18px] font-medium leading-7 text-neutral-800">
              Loading...
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
