import React, { useEffect, useState } from 'react';
import { Camera, CameraResultType } from '@capacitor/camera';
import { DamageList } from '../../components/Properties/DamageList';
import { AreaInfo } from '../../components/Properties/AreaInfo';
import { AreaList } from '../../components/Properties/AreaList';
import { DamageEdit } from '../../components/Properties/DamageEdit';
import { UnitInfo } from '../../components/Properties/UnitInfo';
import { useParams } from 'react-router-dom';
import {
  Api,
  InspectionAssetDto,
  useAreaControllerSkipAreaMutation,
  useAreaControllerVerifyAreaMutation,
  useInspectionAssetControllerDeleteAllInspectionAssetsMutation,
  useInspectionAssetControllerDeleteInspectionAssetMutation,
  useInspectionAssetControllerRegisterInspectionAssetMutation,
  usePropertyControllerGetPropertiesQuery,
  useUnitControllerGetNextUnitByIdQuery,
  useUnitControllerGetUnitByIdQuery,
} from '../../store/api/generated/Api';
import { useAppDispatch } from '../../store/store';
import classNames from 'classnames';
import { back } from 'redux-first-history/build/es5/actions';
import { useTailwindBreakpoint } from '../../util/tailwindBreakpointHook';
import { useTranslation } from 'react-i18next';
import { replace } from 'redux-first-history';

export const UnitPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useTailwindBreakpoint();
  const { propertyId, id: unitId } = useParams() as {
    propertyId: string;
    id: string;
    areaId?: string;
  };
  const [damages, setDamages] = useState<InspectionAssetDto[]>([]);
  const [areaId, setAreaId] = useState<string | undefined>();
  const [damageId, setDamageId] = useState<string | undefined>();
  const { t } = useTranslation();

  const { data: properties } = usePropertyControllerGetPropertiesQuery({});
  const { refetch: refetchUnit, data: { unit, lastUpdatedBy, lastUpdatedAt } = {} } =
    useUnitControllerGetUnitByIdQuery(
      {
        propertyId,
        unitId,
      },
      { refetchOnMountOrArgChange: true }
    );
  const areas = unit?.areas || [];
  const [approveArea] = useAreaControllerVerifyAreaMutation();
  const [skipArea] = useAreaControllerSkipAreaMutation();
  const [addInspectionDamage] = useInspectionAssetControllerRegisterInspectionAssetMutation();
  const [removeInspectionDamage] = useInspectionAssetControllerDeleteInspectionAssetMutation();
  const [removeAllInspectionDamages] =
    useInspectionAssetControllerDeleteAllInspectionAssetsMutation();

  const area = areas?.find(({ id }) => id === areaId);
  const damage = damages?.find(({ id }) => id === damageId);
  const property = properties?.find(({ id }) => id === propertyId);
  const totalDamageCount = areas?.reduce((acc, it) => acc + (it?.damages || 0), 0);
  const currentAreaIndex = areaId && areas ? areas.findIndex(({ id }) => id === areaId) : -1;
  const nextAreaIndex =
    currentAreaIndex !== -1
      ? areas?.findIndex((it, i) => i > currentAreaIndex && it.status !== 'verified') ?? -1
      : -1;

  const addDamage = async () => {
    if (areaId) {
      try {
        const images = await Camera.getPhoto({
          allowEditing: false,
          correctOrientation: true,
          // webUseInput: process.env.NODE_ENV === 'development',
          presentationStyle: 'fullscreen',
          resultType: CameraResultType.Uri,
        });

        const files = await Promise.all(
          images
            .map((image) => image.webPath || '')
            .filter((image) => !!image)
            .map(async (imageUrl) => await (await fetch(imageUrl)).blob())
        );

        if (files.length) {
          const addedDamage = await addInspectionDamage({
            areaId,
            createInspectionAssetDto: {
              files,
            },
            // imageUrl: imageUrl as string,
          }).unwrap();

          setDamages([addedDamage, ...damages]);
          refetchUnit();
          setDamageId(addedDamage.id);
        }
      } catch (e) {
        // User canceled or did not allow all permissions
      }
    }
  };

  const removeDamages = async (damagesToRemove: InspectionAssetDto[]) => {
    if (damagesToRemove.length) {
      const damageIdsToRemove = damagesToRemove.map(({ id }) => id);
      if (damagesToRemove.length !== damages.length || damagesToRemove.length === 1) {
        await Promise.all(
          damageIdsToRemove.map((inspectionAssetId) =>
            removeInspectionDamage({
              inspectionAssetId,
            }).unwrap()
          )
        );
        const newDamages = damages.filter(({ id }) => !damageIdsToRemove.includes(id));
        setDamages(newDamages);
        setDamageId(newDamages[0]?.id);
      } else if (areaId) {
        await removeAllInspectionDamages({
          areaId,
        }).unwrap();
        setDamages([]);
        setDamageId(undefined);
      }
      refetchUnit();
    }
  };

  const setDamageInList = (newDamage: InspectionAssetDto) => {
    const index = damages.findIndex(({ id }) => id === newDamage.id);
    if (index !== -1) {
      const newDamages = [...damages];
      newDamages[index] = newDamage;
      setDamages(newDamages);
      setDamageId(newDamage.id);
    }
  };

  const { data: nextUnit } = useUnitControllerGetNextUnitByIdQuery(
    { propertyId, unitId },
    { refetchOnMountOrArgChange: true }
  );

  const onNextArea = () => {
    if (areas && nextAreaIndex !== -1) {
      setAreaId(areas[nextAreaIndex]?.id);
    } else {
      const nextUnitID = nextUnit?.unit.id;
      if (nextUnitID) {
        dispatch(
          replace(`/unit/${encodeURIComponent(propertyId)}/${encodeURIComponent(nextUnitID)}`)
        );

        setDamages([]);
        setAreaId(undefined);
        setDamageId(undefined);
      } else {
        dispatch(back());
      }
    }
  };

  const updateAreaStatus = async (status: 'verified' | 'skipped') => {
    if (areaId) {
      if (status === 'skipped') {
        await skipArea({
          areaId,
        }).unwrap();
      } else {
        await approveArea({
          areaId,
        }).unwrap();
      }
      await refetchUnit();
      if (isMobile) {
        setAreaId(undefined);
      }
      onNextArea();
    }
  };

  useEffect(() => {
    const asyncEfect = async () => {
      const damages = areaId
        ? await dispatch(
            Api.endpoints.areaControllerGetAreaDamages.initiate({ areaId }, { forceRefetch: true })
          ).unwrap()
        : [];
      setDamages(damages);
      setDamageId(damages[0]?.id);
    };

    asyncEfect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);

  return (
    <div className="relative mx-auto flex min-h-screen flex-1 flex-col bg-white xl:max-w-[100rem] xl:px-12 xl:pt-2">
      <div
        className={classNames([
          'mx-4 border-b border-zinc-200 pb-5 pt-6 lg:mx-8 lg:pb-0',
          !!areaId && 'hidden lg:block',
        ])}
      >
        <UnitInfo
          unit={unit}
          property={property}
          damageCount={totalDamageCount}
          lastUpdatedBy={lastUpdatedBy}
          lastUpdatedAt={lastUpdatedAt}
          goBack={() => dispatch(back())}
        />
      </div>
      <div className="mt-[1.375rem] flex h-auto flex-1 gap-8 px-4 pb-2 lg:px-8 lg:pb-4">
        <AreaList
          area={area}
          areas={areas}
          setArea={({ id }) => setAreaId(id)}
          className={classNames([
            'w-full flex-shrink-0 lg:w-[20.813rem]',
            !!area && 'hidden lg:flex',
          ])}
        />
        <div
          className={classNames([
            'relative min-w-0 flex-1 flex-col gap-2.5',
            !area && 'hidden lg:flex',
          ])}
        >
          {!!area && (
            <div
              className={classNames([
                'mb-2.5 flex flex-col gap-3',
                !!area.damages && 'border-b border-zinc-200',
              ])}
            >
              <AreaInfo area={area} goBack={() => setAreaId(undefined)} />
              {!!area.damages && (
                <DamageList
                  addDamage={addDamage}
                  removeDamages={removeDamages}
                  damages={damages}
                  damage={damage}
                  setDamage={setDamageInList}
                  className="styled-scrollbar mb-1 flex max-w-full gap-2 overflow-auto scroll-smooth pb-1.5 pt-3"
                />
              )}
            </div>
          )}
          <DamageEdit
            area={area}
            damage={damage}
            addDamage={addDamage}
            setDamage={setDamageInList}
            updateAreaStatus={updateAreaStatus}
          />
        </div>
      </div>

      {area && (
        <div className="sticky bottom-0 ml-auto flex w-full items-start justify-start gap-6 bg-white px-8 pb-12 pt-6 shadow lg:w-[calc(100vw-25.313rem)]">
          <div
            onClick={onNextArea}
            className="flex h-10 shrink grow basis-0 cursor-pointer select-none items-center justify-center gap-2 rounded-[30px] bg-green-500 px-4 py-2.5 text-sm font-semibold leading-tight text-white shadow"
          >
            {nextAreaIndex !== -1 ? t('Next room') : t('Next unit')}
          </div>
        </div>
      )}
    </div>
  );
};
