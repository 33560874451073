import React from 'react';
import { AreaDto } from '../../store/api/generated/Api';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import { ReactComponent as BackIcon } from '../../assets/svg/back.svg';

interface AreaInfoProps {
  area: AreaDto;
  goBack?: () => void;
}

export const AreaInfo: React.FC<AreaInfoProps> = ({ area, goBack }) => {
  return (
    <>
      <div className="hidden items-center justify-start gap-1 lg:flex">
        <div className="text-2xl font-semibold leading-7 text-zinc-800">
          {area?.name} {area?.number}
        </div>
        <div className="text-sm font-normal leading-normal text-zinc-600">
          {!!area?.renters.length && (
            <>
              <span className="mx-2">•</span>
              <span>
                {area?.renters.map((renter) => `${renter.firstName} ${renter.lastName}`).join(', ')}
              </span>
            </>
          )}
        </div>
      </div>

      <div className="flex justify-between lg:hidden">
        <div className="flex min-h-[5rem] flex-col gap-5">
          <div className="flex gap-2">
            <BackIcon
              className="relative h-5 w-5 cursor-pointer select-none fill-zinc-600 stroke-zinc-600"
              onClick={goBack}
            />
            <div className="flex flex-col gap-1">
              <div className="flex items-center justify-center gap-8">
                <span className="text-2xl font-semibold leading-7 text-zinc-700">
                  {area?.name} {area?.number}
                </span>
              </div>
              {!!area?.renters.length && (
                <div className="min-h-[1.75rem] whitespace-pre-line font-normal leading-tight text-zinc-600">
                  {area?.renters
                    .map((renter) => `${renter.firstName} ${renter.lastName}`)
                    .join(', \n')}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mr-1 mt-1.5 flex text-sm font-semibold leading-tight text-zinc-600">
          <CloseIcon
            className="ml-6 h-4 w-4 cursor-pointer select-none fill-zinc-600 stroke-zinc-600"
            onClick={goBack}
          />
        </div>
      </div>
    </>
  );
};
