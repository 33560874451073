import { CustomFlowbiteTheme, Accordion as DefaultAccordion } from 'flowbite-react';
import React from 'react';
import { AccordionProps as CoreAccordionProps } from 'flowbite-react/lib/esm/components/Accordion/Accordion';
interface AccordionProps {}

export const accordionCustomTheme: CustomFlowbiteTheme['accordion'] = {
  root: {
    base: '!mb-5',
    flush: {
      off: 'rounded-lg border',
      on: 'border-b',
    },
  },
  content: {
    base: 'px-3.5 py-2.5 last:rounded-b-lg bg-zinc-50 border-x !border-b !border-t-0 border-zinc-100 ',
  },
  title: {
    arrow: {
      base: 'w-9 h-9 shrink-0 fill-zinc-500 -mr-2.5 -mt-0.5',
      open: {
        off: '',
        on: 'rotate-180',
      },
    },
    flush: {
      off: 'bg-white',
      on: 'hover:bg-white',
    },
    base: 'flex w-full bg-white items-center justify-between py-2 px-5 text-left text-zinc-950 font-medium dark:text-gray-400 focus:ring-0',
    open: {
      off: 'bg-white !border !border-zinc-300 rounded-lg',
      on: 'bg-zinc-50 border-t border-x border-zinc-100 rounded-t-lg',
    },
  },
};

export const Accordion: React.FC<AccordionProps & CoreAccordionProps> = (props) => {
  return <DefaultAccordion {...props} theme={accordionCustomTheme} />;
};
