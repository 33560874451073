import ImageGallery from 'react-image-gallery';
import { Portal } from 'react-portal';
import React from 'react';
import { FloatingFocusManager, useDismiss, useFloating, useInteractions } from '@floating-ui/react';

export const ImagesLightbox: React.FC<{
  images: string[];
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  startIndex?: number;
}> = ({ images, isOpen, setIsOpen, startIndex }) => {
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    strategy: 'absolute',
  });
  const dismiss = useDismiss(context);
  const { getFloatingProps } = useInteractions([dismiss]);
  return (
    <Portal>
      {isOpen && (
        <div className="fixed left-0 top-0 z-20 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.5)]">
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className="!left-auto !top-auto max-h-full max-w-full"
          >
            <FloatingFocusManager modal={true} context={context} visuallyHiddenDismiss>
              <ImageGallery
                showIndex={true}
                showPlayButton={false}
                showNav={true}
                startIndex={startIndex}
                showFullscreenButton={false}
                items={images.map((original) => ({ original }))}
              />
            </FloatingFocusManager>
          </div>
        </div>
      )}
    </Portal>
  );
};
