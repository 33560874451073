import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as BackIcon } from '../../assets/svg/back.svg';
import { useTranslation } from 'react-i18next';
import { back } from 'redux-first-history/build/es5/actions';
import { useAppDispatch } from '../../store/store';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import { ReactComponent as FileBundleImage } from '../../assets/svg/file-bundle.svg';
import {
  Api,
  ClaimDto,
  PropertyDto,
  UnitControllerGetUnitsForPropertyApiResponse,
  UnitDto,
} from '../../store/api/generated/Api';
import { useParams } from 'react-router-dom';
import { useServerTable } from '../../util/useServerTable';
import { Table } from '../../components/table/Table';
import { TableHead } from '../../components/table/TableHead';
import { TableHeadCell } from '../../components/table/TableHeadCell';
import { TableBody } from '../../components/table/TableBody';
import { TableRow } from '../../components/table/TableRow';
import { TableCell } from '../../components/table/TableCell';
import moment from 'moment/moment';
import { PaginationWithIcons } from '../../components/Pagination';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Accordion, accordionCustomTheme } from '../../components/accordion/Accordion';
import { AccordionPanel } from '../../components/accordion/AccordionPanel';
import { AccordionTitle } from '../../components/accordion/AccordionTitle';
import { AccordionContent } from '../../components/accordion/AccordionContent';
import { replace } from 'redux-first-history';
import classNames from 'classnames';
import { useResizeTrigger } from '../../util/useResizeTrigger';

export const CreateClaimPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { propertyId, claimId } = useParams() as {
    propertyId: string;
    claimId?: string;
  };
  const [claim, setClaim] = useState<ClaimDto>();
  const [property, setProperty] = useState<PropertyDto>();
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const [allUnitsChecked, setAllUnitsChecked] = useState<boolean>(false);
  const { resizeTrigger } = useResizeTrigger();

  useEffect(() => {
    const asyncEffect = async () => {
      if (claimId) {
        try {
          const response = await dispatch(
            Api.endpoints.claimControllerGetClaimById.initiate(
              { claimId, propertyId },
              { forceRefetch: true }
            )
          ).unwrap();
          setClaim(response.claim);
          setProperty(response.property);
        } catch (e) {}
      } else {
        setClaim(undefined);
        if (propertyId) {
          const properties = await dispatch(
            Api.endpoints.propertyControllerGetProperties.initiate({})
          ).unwrap();
          setProperty(properties.find(({ id }) => id === propertyId));
        } else {
          setProperty(undefined);
        }
      }
    };
    asyncEffect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimId, propertyId]);

  const {
    currentPage,
    searchValue,
    setSearchValue,
    hasSorting,
    tableData,
    infiniteScrollItems,
    setCurrentPage,
    fetchMoreItems,
  } = useServerTable<UnitDto, UnitControllerGetUnitsForPropertyApiResponse>({
    fetchItems: useMemo(
      () =>
        async ({ sort, currentPage, search }) => {
          try {
            if (property) {
              return await dispatch(
                Api.endpoints.unitControllerGetUnitsForProperty.initiate(
                  {
                    pageSize: 15,
                    propertyId: property.id,
                    pageNumber: currentPage,
                    filter: sort?.column,
                    sort: sort?.direction,
                    search,
                    status: 'verified',
                    damages: true,
                  },
                  { forceRefetch: true }
                )
              ).unwrap();
            }
          } catch (e) {}
          return {
            items: [],
            totalPages: 0,
            currentPage: 0,
            searchTerm: '',
            totalItems: 0,
          };
        },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [property]
    ),
    defaultSetValue: [],
  });

  const toggleUnitSelection = (id: string) => {
    const itemIndex = selectedUnits.indexOf(id);
    setSelectedUnits(
      itemIndex !== -1 ? selectedUnits.filter((x) => x !== id) : [...selectedUnits, id]
    );
  };

  const onCreateClaim = async () => {
    dispatch(
      replace(
        `/claims/${encodeURIComponent(propertyId)}/step-2/${encodeURIComponent(
          allUnitsChecked ? 'all' : selectedUnits.join(',')
        )}`
      )
    );
  };

  return (
    <div className="relative flex min-h-screen flex-col items-center bg-white">
      <div className="flex w-full flex-1 flex-col gap-5 px-4 pb-4 lg:px-12 xl:max-w-[100rem] xl:px-20 xl:pt-4">
        <div className="pt-6 xl:mb-3 xl:border-b xl:border-gray-300 xl:pb-3">
          <div className="flex flex-col justify-between gap-5 lg:flex-row">
            <div className="flex flex-col gap-5 border-b border-zinc-200 pb-4 lg:border-0 lg:pb-0">
              <div className="flex gap-2">
                <BackIcon
                  className="relative h-5 w-5 cursor-pointer select-none fill-zinc-600 stroke-zinc-600"
                  onClick={() => dispatch(back())}
                />
                <div className="flex flex-col gap-1">
                  <div className="flex items-center justify-center gap-8">
                    <span className="text-2xl font-semibold leading-7 text-zinc-700">
                      {claim ? t('Edit claim {{name}}', { name: claim.name }) : t('Create claim')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative flex flex-1 gap-1.5 self-stretch rounded-101 lg:mr-5 lg:justify-end">
              <div className="relative flex-1 lg:flex lg:justify-end">
                <div className="pointer-events-none absolute left-3 top-2.5 flex items-center lg:relative lg:-right-[2.3rem] lg:left-auto lg:top-0">
                  <SearchIcon className="h-6 w-6 fill-zinc-500 stroke-zinc-500" />
                </div>
                <input
                  value={searchValue}
                  onInput={(e) => setSearchValue((e.target as HTMLInputElement).value)}
                  placeholder={t('Search rooms, units, etc')}
                  type="search"
                  className="h-11 w-full rounded-101 p-4 pl-10 text-base font-normal leading-normal text-zinc-500 !outline-none !ring-transparent duration-300 ease-in lg:w-72 lg:focus:w-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="relative flex w-full flex-col items-start justify-start gap-2.5 rounded-xl border border-green-500 bg-emerald-50 px-8 py-6">
          <FileBundleImage className="absolute bottom-0 right-0 h-1/2 lg:right-12 lg:h-full" />
          <div className="relative self-stretch text-lg font-semibold leading-7 text-green-700">
            {t('Select units')}
          </div>
          <div className="relative self-stretch text-base font-normal leading-normal text-zinc-800">
            {t(
              'We automatically selected all of the units with damages. You can still manually select.'
            )}
          </div>
        </div>

        <div className="relative hidden rounded-xl border border-zinc-50 bg-white pb-3 shadow-md sm:rounded-lg lg:block ">
          <input
            className="checkbox-green form-checkbox absolute left-2 top-3.5 z-10 xl:left-6"
            type="checkbox"
            name="remember"
            checked={allUnitsChecked}
            onChange={() => setAllUnitsChecked(!allUnitsChecked)}
          />
          <Table hoverable className="border-b border-zinc-200 ">
            <TableHead>
              <TableHeadCell
                scope="col"
                {...hasSorting('number')}
                className="flex items-center gap-2"
              >
                <span className="pl-9">{t('Unit')}</span>
              </TableHeadCell>
              <TableHeadCell scope="col" {...hasSorting('lastUpdatedAt')}>
                {t('Last Inspection')}
              </TableHeadCell>
              <TableHeadCell scope="col">{t('Renters')}</TableHeadCell>
              <TableHeadCell scope="col">{t('Damages')}</TableHeadCell>
            </TableHead>
            <TableBody>
              {tableData.items?.map(({ number, lastUpdatedAt, id, renters, damages }) => (
                <TableRow
                  className="cursor-pointer select-none"
                  key={id}
                  onClick={() => toggleUnitSelection(id)}
                >
                  <TableCell className="flex items-center gap-2 !text-lg !font-medium">
                    <input
                      className="checkbox-green form-checkbox"
                      type="checkbox"
                      name="remember"
                      checked={selectedUnits.includes(id) || allUnitsChecked}
                      onChange={() => {}}
                    />
                    <span>{number}</span>
                  </TableCell>
                  <TableCell>{lastUpdatedAt ? moment(lastUpdatedAt).format('LLL') : ''}</TableCell>
                  <TableCell className="whitespace-pre-line">{renters.join(', \n')}</TableCell>
                  <TableCell>{damages}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <PaginationWithIcons
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={tableData.totalPages}
          />
        </div>

        <div className="w-full lg:hidden">
          <InfiniteScroll
            next={fetchMoreItems}
            hasMore={currentPage < infiniteScrollItems.totalPages}
            loader={<></>}
            dataLength={(infiniteScrollItems.items?.length || 0) * 2}
          >
            <Accordion className="flex flex-col gap-3.5 border-none" collapseAll={true}>
              {infiniteScrollItems.items?.map(
                ({ number, lastUpdatedAt, id, lastUpdatedBy, renters, damages }) => (
                  <AccordionPanel key={id}>
                    <AccordionTitle
                      theme={{
                        arrow: accordionCustomTheme?.title?.arrow,
                      }}
                      className="flex-1 !text-lg !font-medium"
                    >
                      <div
                        className="flex cursor-pointer select-none items-center gap-2 pr-5"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleUnitSelection(id);
                        }}
                      >
                        <input
                          className="checkbox-green form-checkbox"
                          type="checkbox"
                          name="remember"
                          checked={selectedUnits.includes(id) || allUnitsChecked}
                          onChange={() => {}}
                        />
                        <div className="!text-lg !font-medium">{number}</div>
                      </div>
                    </AccordionTitle>
                    <AccordionContent className="-mt-3.5 rounded-b-lg">
                      <div className="mb-2 flex flex-row justify-between">
                        <p className="text-base font-medium not-italic text-zinc-800">
                          {t('Last inspected by')}
                        </p>
                        <p className="text-base font-medium not-italic text-zinc-600">
                          {lastUpdatedBy}
                        </p>
                      </div>
                      <div className="mb-3 flex flex-row justify-between text-zinc-800">
                        <p className="text-base font-medium not-italic text-zinc-800">
                          {t('Last update')}
                        </p>
                        <p className="text-base font-normal not-italic text-zinc-600">
                          {lastUpdatedAt ? moment(lastUpdatedAt).format('LL') : ''}
                        </p>
                      </div>
                      <div className="mb-1 flex flex-row justify-between">
                        <p className="text-base font-medium not-italic text-zinc-800">
                          {t('Renter')}
                        </p>
                        <p className="whitespace-pre-line text-base font-normal not-italic text-zinc-600">
                          {renters.join(', \n')}
                        </p>
                      </div>
                      <div className="mb-2 flex flex-row justify-between text-zinc-800">
                        <p className="text-base font-medium not-italic text-zinc-800">
                          {t('Damages')}
                        </p>
                        <p className="text-base font-normal not-italic text-zinc-600">{damages}</p>
                      </div>
                    </AccordionContent>
                  </AccordionPanel>
                )
              ) || <></>}
            </Accordion>
          </InfiniteScroll>
        </div>
      </div>

      <div
        className={classNames([
          'sticky left-0 mt-auto flex w-full flex-col items-center justify-between gap-3 divide-y bg-white px-12 pb-12 pt-6 shadow-xl lg:flex-row lg:gap-6 lg:divide-none',
          !resizeTrigger && 'bottom-0',
        ])}
      >
        <div className="flex gap-4">
          <span>{t('Step 1 of 2')}</span>
          <span>{t('Select Units')}</span>
        </div>
        <div className="flex w-full justify-center gap-3 pt-3 lg:w-auto lg:justify-start lg:pt-0">
          <div
            onClick={() => dispatch(back())}
            className="flex h-10 w-40 cursor-pointer select-none items-center justify-center gap-2 rounded-[30px] border border-zinc-300 bg-white px-4 py-2.5 text-sm font-semibold leading-tight shadow"
          >
            {t('Cancel')}
          </div>
          <div
            onClick={allUnitsChecked || !!selectedUnits.length ? onCreateClaim : () => {}}
            className={classNames([
              'flex h-10 w-40 cursor-pointer select-none items-center justify-center gap-2 rounded-[30px] bg-green-500 px-4 py-2.5 text-sm font-semibold leading-tight text-white shadow',
              !allUnitsChecked && !selectedUnits.length && '!cursor-not-allowed opacity-50',
            ])}
          >
            {t('Next')}
          </div>
        </div>
      </div>
    </div>
  );
};
