import { CustomFlowbiteTheme, Dropdown } from 'flowbite-react';
import React, { useState } from 'react';
import { ReactComponent as BuildingIcon } from '../assets/svg/building-icon.svg';
import classNames from 'classnames';
import { ReactComponent as SearchIcon } from '../assets/svg/search.svg';
import { PropertyDto } from '../store/api/generated/Api';
import { useTranslation } from 'react-i18next';

export const customTheme: CustomFlowbiteTheme['dropdown'] = {
  arrowIcon: 'ml-2 h-4 w-4 -mr-2.5',
  content: '',
  floating: {
    animation: 'transition-opacity',
    arrow: {
      base: 'absolute z-10 h-2 w-2 rotate-45',
      style: {
        dark: 'bg-zinc-900 dark:bg-zinc-700',
        light: 'bg-white',
        auto: 'bg-white dark:bg-zinc-700',
      },
      placement: '-4px',
    },
    base: 'z-10 w-fit rounded divide-y divide-zinc-100 shadow !outline-none !ring-transparent',
    content: 'text-sm text-zinc-700 dark:text-zinc-200',
    divider: 'my-1 h-px bg-zinc-100 dark:bg-zinc-600',
    header: 'block py-2 px-4 text-sm text-zinc-700 dark:text-zinc-200 !bg-red-100',
    hidden: 'hidden',
    item: {
      base: 'flex items-center justify-start py-2 px-4 text-sm text-zinc-700 cursor-pointer hover:bg-zinc-100 dark:text-zinc-200 dark:hover:bg-zinc-600 dark:hover:text-white ',
      icon: 'mr-2 h-4 w-4',
    },
    style: {
      dark: 'bg-zinc-900 text-white dark:bg-zinc-700',
      light: 'border border-zinc-200 bg-white text-zinc-900',
      auto: 'border border-zinc-200 bg-white text-zinc-900 dark:border-none dark:bg-zinc-700 dark:text-white',
    },
    target: 'lg:w-fit',
  },
  inlineWrapper: 'flex items-center p-2 px-5 rounded-lg border border-zinc-400 font-semibold',
};

export interface DropdownOption {
  text: string;
  value: string;
}

export interface DropdownItemProps {
  options?: PropertyDto[];
  value?: PropertyDto;
  onInput: (val: PropertyDto) => void;
  className?: string;
  searchable?: boolean;
}

export const DropdownItems: React.FC<DropdownItemProps> = ({
  options,
  value,
  onInput,
  className,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const filteredOptions = (options || []).filter((x) =>
    x.name.toLowerCase().includes(search.toLowerCase())
  );

  const onItemClick = (property: PropertyDto) => {
    onInput(property);
    setSearch('');
  };

  return (
    <Dropdown
      label={
        <div className={classNames(['flex space-x-4', className])}>
          <BuildingIcon className="h-5 w-5 fill-zinc-500 stroke-zinc-500" />
          <div>{value?.name}</div>
        </div>
      }
      theme={customTheme}
      dismissOnClick={true}
      inline
    >
      <div className="relative my-3 flex w-full justify-center px-3">
        <SearchIcon className="absolute left-5 top-0.5 h-6 w-6 fill-zinc-500 stroke-zinc-500" />
        <input
          type="search"
          className="h-7 w-full rounded-full indent-5"
          value={search}
          placeholder={t('Search properties')}
          onInput={(e) => setSearch((e.target as HTMLInputElement).value)}
          onKeyDown={(e) => e.stopPropagation()}
          tabIndex={-1}
        />
      </div>
      {filteredOptions.map((property) => (
        <Dropdown.Item key={property.id} value={property.id} onClick={() => onItemClick(property)}>
          {property.name}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};
