import { createSelector, createSlice, Draft } from '@reduxjs/toolkit';
import { RUNTIME_STATE_NAME, RuntimeState } from '../models/RuntimeState';
import { Api } from '../api/generated/Api';

const onFetchUser = (state: Draft<RuntimeState>) => {
  state.userCheck = true;
};

export const RuntimeSlice = createSlice({
  name: RUNTIME_STATE_NAME,
  initialState: {
    userCheck: false,
    mobileMenuOpen: false,
    loading: false,
  } as RuntimeState,
  reducers: {
    setUserCheck: (state, { payload }: { payload: boolean }) => {
      state.userCheck = payload;
    },
    setMobileMenuOpen: (state, { payload }: { payload: boolean }) => {
      state.mobileMenuOpen = payload;
      if (payload) {
        const scroll = document.documentElement.scrollTop;
        document.documentElement.classList.add('menu-open');
        document.body.scrollTop = scroll;
      } else {
        const scroll = document.body.scrollTop;
        document.documentElement.classList.remove('menu-open');
        document.documentElement.scrollTop = scroll;
      }
    },
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(Api.endpoints.userControllerGetUserProfile.matchFulfilled, onFetchUser);
    builder.addMatcher(Api.endpoints.userControllerGetUserProfile.matchRejected, onFetchUser);
  },
});

type PartialState = {
  [RuntimeSlice.name]: RuntimeState;
  [key: string]: any;
};

export const userCheckSelector = createSelector(
  (state: PartialState) => state[RuntimeSlice.name].userCheck,
  (it) => it
);

export const mobileMenuOpenSelector = createSelector(
  (state: PartialState) => state[RuntimeSlice.name].mobileMenuOpen,
  (it) => it
);

export const loadingSelector = createSelector(
  (state: PartialState) => state[RuntimeSlice.name].loading,
  (it) => it
);

export const { setUserCheck, setMobileMenuOpen, setLoading } = RuntimeSlice.actions;
