import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactComponent as ChevronIcon } from '../../assets/svg/chevron.svg';
import { ReactComponent as ContactIcon } from '../../assets/svg/contact.svg';
import { ReactComponent as ContactMultipleIcon } from '../../assets/svg/contact-multiple.svg';
import { AreaDto } from '../../store/api/generated/Api';
import { ReactComponent as VerifiedIcon } from '../../assets/svg/verified.svg';
import { ReactComponent as SkippingIcon } from '../../assets/svg/skipped.svg';

interface AreaListProps {
  areas?: AreaDto[];
  area?: AreaDto;
  setArea: (area: AreaDto) => void;
  className?: string;
}

export const AreaList: React.FC<AreaListProps> = ({ areas, area, setArea, className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(['flex flex-col gap-8', className])}>
      <div className="flex flex-col gap-4">
        <div className="self-stretch font-semibold leading-7 text-zinc-700">{t('Rooms')}</div>
        <div className="flex flex-col gap-3">
          {areas?.map((item) => (
            <div
              key={item.id}
              className="flex h-11 cursor-pointer select-none flex-col bg-white"
              onClick={() => setArea(item)}
            >
              <div className="flex h-11 flex-col gap-1.5 self-stretch">
                <div
                  className={classNames([
                    'flex items-center justify-start gap-2 self-stretch rounded-lg border px-3.5 py-2.5',
                    item.id === area?.id ? 'border-green-500' : 'border-zinc-400',
                  ])}
                >
                  <div className="flex h-6 shrink grow basis-0 items-center justify-start gap-2">
                    {item.renters.length === 1 && (
                      <ContactIcon className="relative h-5 w-5 fill-zinc-950 stroke-zinc-950" />
                    )}
                    {item.renters.length > 1 && (
                      <ContactMultipleIcon className="relative h-5 w-5 fill-zinc-950 stroke-zinc-950" />
                    )}
                    <div className="font-medium text-zinc-950">
                      {item.name} {item.number}
                    </div>
                  </div>
                  {item.status === 'verified' && (
                    <>
                      <span className="my-1 mr-1.5 text-sm font-semibold leading-tight text-zinc-900">
                        ({item.damages})
                      </span>
                      <div className="flex h-6 w-6 shrink-0 items-center rounded-full bg-green-100">
                        <VerifiedIcon className="ml-1 h-4 w-4 shrink-0 fill-green-600 stroke-green-600" />
                      </div>
                    </>
                  )}
                  {item.status === 'skipped' && (
                    <>
                      <span className="my-1 mr-1.5 text-sm font-semibold leading-tight text-zinc-900">
                        ({item.damages})
                      </span>
                      <div className="flex h-6 w-6 shrink-0 items-center rounded-full bg-yellow-100">
                        <SkippingIcon className="ml-1 h-4 w-4 shrink-0 fill-yellow-400 stroke-yellow-400" />
                      </div>
                    </>
                  )}
                  <ChevronIcon className="relative h-5 w-5 fill-zinc-500 stroke-zinc-500" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
