import { Mutex } from '../../util/mutex';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { Api } from './generated/Api';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AUTH_STATE_NAME, AuthState } from '../models/AuthState';
import { baseQuery, refreshEndpoint } from './baseQuery';
import { refreshToken as refreshTokenAction } from '../actions/AuthActions';

const mutex = new Mutex();

export const interceptedBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const isRefreshEndpoint =
    api.endpoint === refreshEndpoint && !!Api.endpoints[refreshEndpoint].initiate(undefined);

  // Avoid locking refresh request
  if (!isRefreshEndpoint) {
    try {
      // wait until requests have been unlocked
      await mutex.waitForUnlock();
    } catch (e) {
      return Promise.resolve({
        status: 401,
        data: {
          message: 'Unauthenticated.',
          statusCode: 401,
        },
      }) as PromiseLike<QueryReturnValue<unknown, FetchBaseQueryError, {}>>;
    }
  }

  let result = await baseQuery(args, api, extraOptions);
  const { isAuthenticated, refreshToken } = (api.getState() as any)[AUTH_STATE_NAME] as AuthState;
  if (!isRefreshEndpoint && result.error?.status === 401 && isAuthenticated && refreshToken) {
    const { release, block } = await mutex.acquire();

    try {
      await api.dispatch(refreshTokenAction()).unwrap();

      result = await baseQuery(args, api, extraOptions);
    } catch (e) {
      // Block all requests because token refresh has failed
      block();
    } finally {
      release();
    }
  }

  return result;
};
