import React, { Suspense } from 'react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { history } from './store/store';
import { AppRouter } from './App.router';
import { AuthCheck } from './components/Auth/AuthCheck';
import { Loading } from './components/Loading';
import { ScrollToTop } from './components/ScrollToTop';
import { ServerErrors } from './components/ServerErrors';
const App: React.FC = () => (
  <Suspense fallback={<Loading />}>
    <Router history={history}>
      <AppRouter />
    </Router>
    <AuthCheck />
    <ScrollToTop />
    <ServerErrors />
  </Suspense>
);

export default App;
