import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LowIcon } from '../../assets/svg/damage-low.svg';
import { ReactComponent as MediumIcon } from '../../assets/svg/damage-medium.svg';
import { ReactComponent as HighIcon } from '../../assets/svg/damage-high.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/close-rounded.svg';
import classNames from 'classnames';
import {
  InspectionAssetDto,
  useInspectionAssetControllerGetDamageTypesQuery,
} from '../../store/api/generated/Api';
import { apiUrl } from '../../util/apiUrl';

interface DamageListProps {
  damage?: InspectionAssetDto;
  damages: InspectionAssetDto[];
  addDamage: () => void;
  removeDamages: (damages: InspectionAssetDto[]) => void;
  setDamage: (damage: InspectionAssetDto) => void;
  className?: string;
}

export const DamageList: React.FC<DamageListProps> = ({
  addDamage,
  removeDamages,
  className,
  damages,
  damage,
  setDamage,
}) => {
  const { t } = useTranslation();
  const [damageRemovalMode, setDamageRemovalMode] = useState(false);
  const { data: damageTypes } = useInspectionAssetControllerGetDamageTypesQuery();

  return (
    <div className={className}>
      {!!damages.length && damageRemovalMode ? (
        <div className="absolute right-0 top-16 flex gap-3 lg:top-0">
          <div
            className="cursor-pointer select-none text-sm font-semibold leading-tight text-red-500"
            onClick={() => removeDamages(damages)}
          >
            {t('Remove all')}
          </div>
          <div
            className="cursor-pointer select-none text-sm font-semibold leading-tight text-zinc-600"
            onClick={() => setDamageRemovalMode(false)}
          >
            {t('Close')}
          </div>
        </div>
      ) : (
        <div
          className="absolute right-0 top-16 cursor-pointer select-none text-sm font-semibold leading-tight text-green-500 lg:top-0"
          onClick={() => setDamageRemovalMode(true)}
        >
          {t('Remove Damages ({{count}})', {
            count: damages.length,
          })}
        </div>
      )}
      <div
        className="flex h-[6.219rem] min-h-[6.219rem] w-[7.75rem] min-w-[7.75rem] cursor-pointer cursor-pointer select-none flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-zinc-500 bg-white"
        onClick={addDamage}
      >
        <PlusIcon className="relative h-6 w-6 fill-zinc-500 stroke-zinc-500" />
        <div className="text-sm font-semibold leading-tight text-zinc-500">{t('Add DAMAGE')}</div>
      </div>
      {damages.map(({ damageLevel, inspectionImages, damageType, id }, i) => {
        const imageUrl = apiUrl((inspectionImages || [])[0]?.thumbnailUrl);
        return (
          <div
            key={id}
            className={classNames([
              'relative flex h-[6.219rem] min-h-[6.219rem] w-[7.75rem] min-w-[7.75rem] cursor-pointer select-none flex-col items-start justify-between rounded-lg !bg-cover !bg-center !bg-no-repeat',
              damage?.id === id ? 'border-4 border-green-500 p-1' : 'p-2',
            ])}
            onClick={() => setDamage(damages[i])}
            style={{
              background:
                damage?.id === id
                  ? `linear-gradient(0deg, rgba(19, 130, 74, 0.8), rgba(19, 130, 74, 0.8)), url("${imageUrl}")`
                  : `linear-gradient(0deg, rgba(0,0,0, 0.8), rgba(0,0,0, 0.8)), url("${imageUrl}")`,
            }}
          >
            {damageLevel === 'low' && (
              <LowIcon className="relative z-1 h-6 w-6 fill-green-500 stroke-green-500" />
            )}
            {damageLevel === 'medium' && (
              <MediumIcon className="relative z-1 h-6 w-6 fill-yellow-300 stroke-yellow-300" />
            )}
            {damageLevel === 'high' && (
              <HighIcon className="relative z-1 h-6 w-6 fill-red-400 stroke-red-400" />
            )}
            <div className="relative z-1 mt-auto text-sm font-semibold leading-tight text-white">
              {damageTypes?.find(({ value }) => value === damageType)?.label || damageType}
            </div>
            {damageRemovalMode && (
              <CloseIcon
                onClick={() => removeDamages([damages[i]])}
                className="absolute -right-3.5 -top-3.5 h-7 w-7 cursor-pointer select-none rounded-full bg-white fill-zinc-500 stroke-zinc-500 p-0.5"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
