export const objectToFormData = (data: { [key: string]: string | Blob[] }) => {
  const form = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'object') {
      value.forEach((val) => form.append(key, val));
    } else {
      form.append(key, value);
    }
  });
  return form;
};
