import React, { useState } from 'react';
import {
  InspectionAssetDto,
  useInspectionAssetControllerGetDamageTypesQuery,
} from '../../store/api/generated/Api';
import { ReactComponent as LowIcon } from '../../assets/svg/damage-low.svg';
import { ReactComponent as MediumIcon } from '../../assets/svg/damage-medium.svg';
import { ReactComponent as HighIcon } from '../../assets/svg/damage-high.svg';
import { useTranslation } from 'react-i18next';
import { apiUrl } from '../../util/apiUrl';
import { ImagesLightbox } from '../Properties/ImagesLightbox';

interface ClaimDamageProps {
  damage: InspectionAssetDto;
  setDamage: (damage: InspectionAssetDto) => void;
}

export const ClaimDamage: React.FC<ClaimDamageProps> = ({ damage, setDamage }) => {
  const { damageType, damageLevel, comments, inspectionImages, cost } = damage;
  const { t } = useTranslation();
  const { data: damageTypes } = useInspectionAssetControllerGetDamageTypesQuery();
  const { value, label } = damageTypes?.find(({ value }) => value === damageType) || {};
  const [imagesModalOpen, setImagesModalOpen] = useState({ open: false, initialIndex: 0 });

  return (
    <>
      <div className="flex flex-col justify-start gap-4 self-stretch print:flex-row print:items-center lg:flex-row lg:items-center">
        <div className="flex flex-1 flex-col justify-start gap-4 print:flex-row print:items-center lg:flex-row lg:items-center">
          <div className="flex items-center justify-start gap-2 print:pl-7 lg:pl-7">
            {!!value && (
              <img
                src={`/assets/svg/damage-types/${value}.svg`}
                className="h-6 w-6"
                alt="damage-type"
              />
            )}
            <div className="text-xl font-semibold leading-loose text-neutral-700">
              {label || damageType}
            </div>
          </div>
          <div className="flex items-center gap-1">
            {damageLevel === 'low' && (
              <>
                <LowIcon className="relative h-5 w-5 fill-green-500 stroke-green-500" />
                <div className="text-sm font-semibold leading-tight text-yellow-500">
                  {t('No damage')}
                </div>
              </>
            )}
            {damageLevel === 'medium' && (
              <>
                <MediumIcon className="relative h-5 w-5 fill-yellow-300 stroke-yellow-300" />
                <div className="text-sm font-semibold leading-tight text-yellow-500">
                  {t('Mild damage')}
                </div>
              </>
            )}
            {damageLevel === 'high' && (
              <>
                <HighIcon className="relative h-5 w-5 fill-red-400 stroke-red-400" />
                <div className="text-sm font-semibold leading-tight text-yellow-500">
                  {t('Heavy damage')}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="hidden text-right text-xl font-semibold leading-[30px] text-neutral-800 print:block">
          $ {cost}
        </div>
        <div className="ml-auto flex h-11 w-[210px] items-center gap-1.5 rounded-lg border border-zinc-400 bg-white px-3.5 print:hidden">
          <div className="text-base font-normal leading-normal text-slate-600">$</div>
          <input
            type="number"
            min={0}
            className="w-auto min-w-[1rem] flex-1 border-none text-base font-semibold leading-normal text-zinc-500 focus:outline-none focus:ring-0"
            value={cost || ''}
            onChange={(e) =>
              setDamage({ ...damage, cost: Math.max(0, Number(e.target.value)) || undefined })
            }
          />
        </div>
      </div>
      <div className="flex h-auto flex-col items-start justify-start self-stretch">
        <div className="mb-4 self-stretch text-lg font-normal leading-7 text-zinc-600 print:ml-8 print:w-3/4 lg:ml-8 lg:w-3/4">
          {comments}
        </div>
      </div>
      <div className="flex break-inside-avoid items-start justify-start gap-3 self-stretch">
        <div className="flex h-auto shrink grow basis-0 flex-wrap items-start justify-start gap-3 print:ml-8 lg:ml-8">
          {inspectionImages.map((image, i) => (
            <img
              key={image.id}
              className="relative h-[10.375rem] rounded-xl"
              src={apiUrl(image.thumbnailUrl)}
              alt="damage"
              onClick={() => setImagesModalOpen({ open: true, initialIndex: i })}
            />
          ))}
        </div>
      </div>
      <ImagesLightbox
        images={inspectionImages.map((image) => apiUrl(image.imageUrl))}
        isOpen={imagesModalOpen.open}
        startIndex={imagesModalOpen.initialIndex}
        setIsOpen={(isOpen) => setImagesModalOpen({ open: isOpen, initialIndex: 0 })}
      />
    </>
  );
};
