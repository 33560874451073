import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store/store';
import { useParams } from 'react-router-dom';
import {
  Api,
  AreaDto,
  ClaimDto,
  InspectionAssetDto,
  PropertyDto,
  UnitDto,
} from '../../store/api/generated/Api';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import { ReactComponent as BackIcon } from '../../assets/svg/back.svg';
import moment from 'moment';
import { ClaimTotalCosts } from '../../components/Claim/ClaimTotalCosts';
import { ClaimAccordion } from '../../components/Claim/ClaimAccordion';
import { back } from 'redux-first-history/build/es5/actions';
import { setToken, tokenSelector } from '../../store/slices/AuthSlice';
import { useSelector } from 'react-redux';
import { apiUrl, getClaimReportUrl } from '../../util/apiUrl';
import { setLoading } from '../../store/slices/RuntimeSlice';
import fileDownload from 'js-file-download';
import { replace } from 'redux-first-history';
import classNames from 'classnames';
import { useResizeTrigger } from '../../util/useResizeTrigger';
import { concatArrays } from '../../util/concatArrays';

export const ClaimPreviewPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const token = useSelector(tokenSelector);

  const {
    propertyId,
    claimId,
    token: paramsToken,
    units: selectedUnits,
  } = useParams() as {
    propertyId: string;
    claimId?: string;
    units?: string;
    token?: string;
  };
  const [claim, setClaim] = useState<ClaimDto>();
  const [property, setProperty] = useState<PropertyDto>();
  const [units, setUnits] = useState<UnitDto[]>([]);
  const { resizeTrigger } = useResizeTrigger();

  useEffect(() => {
    const asyncEffect = async () => {
      // Manage outside print requests of this page
      let tokenCheck = !!token;
      if (!tokenCheck && paramsToken) {
        await dispatch(setToken(paramsToken));
        tokenCheck = true;
      }

      if (tokenCheck) {
        try {
          if (claimId) {
            const response = await dispatch(
              Api.endpoints.claimControllerGetClaimById.initiate(
                { claimId, propertyId },
                { forceRefetch: true }
              )
            ).unwrap();
            setClaim(response.claim);
            setProperty(response.property);
            setUnits(response.units);
          } else if (selectedUnits) {
            const response = await dispatch(
              Api.endpoints.claimControllerGetClaimPreview.initiate(
                {
                  propertyId,
                  ...(selectedUnits === 'all'
                    ? { allUnits: true }
                    : { unitsIds: selectedUnits.split(',') }),
                },
                { forceRefetch: true }
              )
            ).unwrap();
            setClaim(response.claim);
            setProperty(response.property);
            setUnits(response.units);
          }
        } catch (e) {}
      } else {
        setClaim(undefined);
        setUnits([]);
        if (propertyId) {
          const properties = await dispatch(
            Api.endpoints.propertyControllerGetProperties.initiate({})
          ).unwrap();
          setProperty(properties.find(({ id }) => id === propertyId));
        } else {
          setProperty(undefined);
        }
      }
    };
    asyncEffect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimId, propertyId, paramsToken]);

  const save = async (route?: boolean) => {
    const inspectionAssetsCosts = concatArrays(
      concatArrays(units.map(({ areas }) => areas)).map((area) => area.inspectionAssets)
    ).map(({ id, cost }) => ({
      id,
      cost: Number(cost),
    }));
    if (claimId) {
      return await dispatch(
        Api.endpoints.claimControllerUpdateClaim.initiate({
          claimId,
          propertyId,
          updateClaimDto: {
            claimReportUrl: getClaimReportUrl(claimId, propertyId, token),
            inspectionAssetsCosts,
          },
        })
      ).unwrap();
    } else if (selectedUnits) {
      const result = await dispatch(
        Api.endpoints.claimControllerRegisterClaim.initiate({
          propertyId,
          registerClaimDto: {
            claimReportUrl: getClaimReportUrl('{{claimId}}', propertyId, token),
            ...(selectedUnits === 'all'
              ? { allUnits: true }
              : { unitsIds: selectedUnits.split(',') }),
            inspectionAssetsCosts,
          },
        })
      ).unwrap();
      if (route) {
        await dispatch(
          replace(`/claims/edit/${encodeURIComponent(propertyId)}/${encodeURIComponent(result.id)}`)
        );
      }
      return result;
    }
  };

  const saveAndDownload = async () => {
    const {
      id,
      name,
      claimReport: { claimReportUrl },
    } = (await save()) || { claimReport: {} };

    // Download
    if (claimReportUrl) {
      await dispatch(setLoading(true));
      try {
        await fetch(apiUrl(claimReportUrl))
          .then((res) => res.blob())
          .then((blob) => fileDownload(blob, `${name}.pdf` as string));
      } catch (e) {}
      await dispatch(setLoading(false));
    }

    if (id) {
      await dispatch(
        replace(`/claims/edit/${encodeURIComponent(propertyId)}/${encodeURIComponent(id)}`)
      );
    }
  };

  const setDamage = (unit: UnitDto, damage: InspectionAssetDto) => {
    const unitIndex = units.findIndex(({ id }) => id === unit.id);
    const area = unit.areas.find(({ id }) => id === damage.areaId);
    const areaIndex = unit.areas.indexOf(area as AreaDto);
    const damageIndex = area?.inspectionAssets.findIndex(({ id }) => id === damage.id) ?? -1;
    if (![unitIndex, areaIndex, damageIndex].includes(-1)) {
      // Update damage from area in unit
      const newUnits = [
        ...units.slice(0, unitIndex),
        {
          ...unit,
          areas: [
            ...unit.areas.slice(0, areaIndex),
            {
              ...area,
              inspectionAssets: [
                ...(area?.inspectionAssets || []).slice(0, damageIndex),
                damage,
                ...(area?.inspectionAssets || []).slice(damageIndex + 1),
              ],
            } as AreaDto,
            ...unit.areas.slice(areaIndex + 1),
          ],
        } as UnitDto,
        ...units.slice(unitIndex + 1),
      ];
      setUnits(newUnits);
    }
  };

  return (
    <div className="flex min-h-screen w-full flex-col bg-white print:h-full print:overflow-visible xl:mx-auto xl:max-w-[100rem] xl:px-12 xl:pt-4">
      <div className="flex flex-1 flex-col px-8 pt-5 print:pt-6 lg:pt-6">
        <div className="-ml-4 flex flex-col justify-start gap-1 print:ml-0 print:flex-row print:items-center print:gap-4 lg:ml-0 lg:flex-row lg:items-center lg:gap-4">
          <div className="flex items-center gap-2">
            <BackIcon
              className="relative block h-5 w-5 cursor-pointer select-none fill-zinc-600 stroke-zinc-600 print:hidden lg:hidden"
              onClick={() => dispatch(back())}
            />
            <div className="text-2xl font-semibold text-zinc-700 print:text-4xl lg:text-4xl">
              {claim?.name}
            </div>
          </div>
          <div className="hidden h-[0px] w-[22.02px] origin-center rotate-[92.60deg] border border-zinc-300 print:block lg:block"></div>
          {!!claim && (
            <div className="ml-7 text-sm font-medium text-zinc-700 print:ml-0 print:text-xl lg:ml-0 lg:text-xl">
              {claim.date ? moment(claim.date).format('LLL') : ''}
            </div>
          )}
          <CloseIcon
            onClick={() => dispatch(back())}
            className="absolute right-6 top-7 mb-auto ml-auto h-4 w-4 cursor-pointer select-none fill-zinc-600 stroke-zinc-600 print:static print:hidden lg:static"
          />
        </div>
        <div className="flex flex-col gap-2 pt-4 print:pt-9 lg:pt-9 xl:px-8 xl:pt-10">
          <div className="text-xl font-semibold text-zinc-800">{property?.name}</div>
          <div className="text-base font-normal text-zinc-800">{property?.address}</div>
        </div>
        <ClaimTotalCosts className="mt-12 xl:px-8" units={units} />
        <div className="mt-12 text-3xl font-semibold text-zinc-800 xl:mt-20 xl:px-8">
          {t('Damages')}
        </div>
        <div className="mt-10 border-none print:mt-12 lg:mt-12 xl:px-8">
          {units.map((unit) => (
            <ClaimAccordion
              key={unit.id}
              options={unit}
              setDamage={(damage) => setDamage(unit, damage)}
            />
          ))}
        </div>
      </div>

      <div
        className={classNames([
          'sticky right-3 mt-auto flex w-full flex-col items-center justify-between gap-3 bg-white px-6 pb-12 pt-6 shadow-xl print:hidden print:flex-row print:px-12 lg:flex-row lg:px-12',
          !resizeTrigger && 'bottom-0',
        ])}
      >
        <div className="flex w-full justify-center gap-4 border-b border-zinc-300 pb-3 print:w-auto print:border-0 print:pb-0 lg:w-auto lg:border-0 lg:pb-0">
          <span>{t('Step 2 of 2')}</span>
          <span className="font-semibold">{t('Preview and Export')}</span>
        </div>
        <div className="flex w-full flex-wrap justify-center gap-3 px-4 print:w-auto print:flex-nowrap print:justify-start print:px-0 lg:w-auto lg:flex-nowrap lg:justify-start lg:px-0">
          <div
            onClick={() => dispatch(back())}
            className="flex h-auto w-[calc(50%-0.4rem)] cursor-pointer select-none items-center justify-center gap-2 rounded-[30px] border border-zinc-300 bg-white px-4 py-2.5 text-sm font-semibold leading-tight shadow"
          >
            {t('Back')}
          </div>
          <div
            onClick={() => save(true)}
            className="flex h-auto w-[calc(50%-0.4rem)] cursor-pointer select-none items-center justify-center gap-2 rounded-[30px] border border-zinc-300 bg-white px-4 py-2.5 text-sm font-semibold leading-tight shadow"
          >
            {t('Save')}
          </div>
          <div
            onClick={saveAndDownload}
            className="flex h-10 w-full cursor-pointer select-none items-center justify-center gap-2 whitespace-nowrap rounded-[30px] bg-green-500 px-4 py-2.5 text-sm font-semibold leading-tight text-white shadow print:w-auto lg:w-auto"
          >
            {t('Save and Download')}
          </div>
        </div>
      </div>
    </div>
  );
};
