export const AUTH_STATE_NAME = 'auth';

export interface AuthState {
  user: User | null;
  token?: string | null;
  refreshToken?: string | null;
  isAuthenticated: boolean;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt?: string;
  updatedAt?: string;
}
